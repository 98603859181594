import React from 'react';
import { Button } from 'antd';

import illustration from '../../assets/selection.svg'
import history from '../../services/history';

export default function Home() {
  return (
    <div className="container-home">
      <div>
        <h5>TRABALHE CONOSCO</h5>
        <h6 style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 14 }}>Selecione uma opção</h6>

        <Button
          id="btn_cadastrar_fornecedor"
          className="ant-input"
          style={{ margin: '20px 0' }}
          onClick={() => history.push('/trabalhe-conosco/pessoa-fisica')}
        >
          Pessoa física
        </Button>

        <Button
          id="btn_cadastrar_fornecedor"
          className="ant-input"
          onClick={() => history.push('/trabalhe-conosco/pessoa-juridica')}
        >
          Pessoa jurídica
        </Button>
      </div>

      <img className="img-illustration" src={illustration} alt="selection" />
    </div>
  );
}
