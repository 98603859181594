import { createGlobalStyle } from 'styled-components'
import { darken, lighten } from 'polished'
import { primary } from './colors'

export default createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html, body, #root {
  height: 100%;

}

body {
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font: 14px 'Montserrat', sans-serif;
  font-family: 'Montserrat', sans-serif!important;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.title-section {
  color: #707070;
  text-align: left;
}

/* bootstrap class manipuled */
.bg-light {
  background-color: #FFFFFF!important;
}

.nav-link:hover {
  color: #29334D!important;
}
/* en bootstrap class manupuled */

/* ant manipuled */
#btn-login:hover{
  color: #FFF!important;
}
#btn-login:focus{
  color: #FFF!important;
}
#btn_cadastrar_fornecedor {
  height: 54px;
  letter-spacing: 2px;
  background-color: #569A98!important;
  border: 0px;
  font-size: 19px;
  color: #FFF;
}
#btn_cadastrar_fornecedor:hover {
  background-color: ${darken('0.1', '#569A98')}!important;
  color: #FFF!important;
}
.ant-btn:active, .ant-btn.active {
  border-color: #364364!important;
  color: #FFF!important;
}
#btn-add-dashboard:active {
  border-color: #364364!important;
  color: #364364!important;
}
#btn-add-dashboard:hover {
  border-color: #364364!important;
  color: #364364!important;
}
.ant-btn:hover, .ant-btn:focus {
  border-color: #364364!important;
  /* color: #FFF!important; */
}
.ant-btn-primary{
  background-color: #485985!important;
}

/* .site-page-header {
  border: 1px solid rgb(235, 237, 240);
} */
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.site-page-header .ant-page-header-heading-left span {
  font-weight: normal!important;
}
.ant-page-header-heading-left {
  flex-wrap: wrap!important;
}

.ant-select-selection-search-input::-webkit-select-placeholder {
  height: 42px!important;
}
.ant-select-selector {
  height: 100%!important;
  align-items: center!important;
  min-height: 42px;
}
.size_select{
  height: 42px!important;
}
.ant-input {
  height: 42px!important;
}
.ant-upload {
  width: 100%!important;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
}
.custom-form-item {
  display: flex;
  justify-content: center!important;
}
.custom-margin-form-item {
  display: flex;
  justify-content: center!important;
  margin-bottom: 7px!important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000!important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #000!important;
}
::-webkit-input-placeholder { /* Chrome, Safari */
  text-transform: initial;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.calendar-ant {
  display: flex;
  height: 42px!important;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 14px!important;
}
/* end ant manipuled */

/* BOOTSTRAP STYLE */
.navbar-light .navbar-toggler {
  border: none!important;
}
/* END BOOTSTRAP STYLE */

/* SOCIAL MEDIA */
@import "../variables";
@import "../../bourbon/bourbon";

.section-footer {
  background-color: ${primary};

  @media screen and (max-width: 640px){
    text-align: center;
  }

}

.social_footer {

  text-align: center!important;
  font-size: 0!important;
  padding-top: 30px!important;
}

.social_footer .icon {
  display: inline-block!important;
  margin-left: 5px!important;
  color: lighten(#7B8CB8, 50%)!important;
}

.social_footer .icon:first-child {
  margin-left: 0!important;
}

.social_footer a {
  display: table-cell!important;
  width: 40px!important;
  height: 40px!important;
  text-align: center!important;
  vertical-align: middle!important;
  -webkit-border-radius: 50%!important;
  border-radius: 50%!important;
  background-clip: padding-box!important;
  margin-right: 5px!important;
  border: solid 1px #364364!important;
  background-color: #485985!important;
  color: #364364!important;

  &:hover {
	border: solid 1px lighten(#7B8CB8, 30%)!important;
	color: ${lighten('0.3', '#7B8CB8')}!important;
	background-color: #7B8CB8!important;
  }

}

.social_footer a i {
  font-size: 16px!important;
  vertical-align: middle!important;
}

.widget {
  p {
	font-size: 0.7em!important;
    line-height: 1.7em!important;
	  color: #FFF!important;
    margin-bottom: 3px!important;
  }
}

footer#page-footer {
  .copyright {
	color: #7B8CB8!important;
  }
  background-color: darken(#7B8CB8, 7%)!important;
}

.container-home{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.img-illustration{
  padding-left: 10px;
  margin-top: 30px;
  height: 260px;
}

`;
