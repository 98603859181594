import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import history from './services/history';
import GlobalStyle from './styles/global'

import { store, persistor } from './redux'
import { ConfigProvider } from 'antd';
// import dotenv from "dotenv"
import locale from 'antd/es/date-picker/locale/pt_BR'
require('dotenv').config()

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <GlobalStyle />
          {/* <ToastContainer autoClose={3000} /> */}
          <ConfigProvider locale={locale}>
            <Routes />
          </ConfigProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
