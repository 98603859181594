export const CONHECIMENTOS_DIVERSOS_INFORMATICA = [
  'Excel', 'Internet', 'Windows', 'Word', 'Linux', 'Power Point', 'BR Office', 'Adobe Photoshop',
  'Adobe Premiere', 'Corel Draw', 'Outlook', 'FTP', 'Skype'
]
export const MANEQUIM_BLUSA = ['PP', 'P', 'M', 'G', 'GG', 'EXG']
export const TOM_PELE = ['Branca', 'Negra', 'Parda', 'Amarela', 'Indígena']
export const TOM_CABELO = ['Preto', 'Ruivo', 'Loiro', 'Castanho']
export const TIPO_CABELO = ['Crespo', 'Liso', 'Ondulado']
export const COR_OLHOS = ['Azul', 'Castanho', 'Preto', 'Cinza', 'Verde', 'Violeta', 'Vermelho']

export const UF = [
  'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
  'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RO', 'RS', 'RR', 'SC', 'SE', 'SP', 'TO'
]

export const ORGAO_EXPEDIDOR = [
  'SSP', 'OAB', 'MMA', 'MEX', 'MAE', 'M MILITAR', 'I CLA', 'EST', 'DPF', 'DETRAN', 'CRV', 'CRRC', 'CRQ',
  'CRPRE', 'CRP', 'CRO', 'CRN', 'CRM', 'CRF', 'CREFIT', 'CRECI', 'CREA', 'CRE', 'CRC', 'CRB', 'CRAS',
  'CRA', 'COREN', 'OUTRO'
]

export const IDIOMAS = [
  'Africanêr', 'Alemão', 'Búlgado', 'Cazaque', 'Catalão', 'Checo', 'Chinês', 'Coreano', 'Croata', 'Curdo',
  'Dinamarquês', 'Eslovaco', 'Espanhol', 'Fijiano', 'Finlandês ou finês', 'Francês', 'Georgiano', 'Grego', 'Guarani',
  'Hebreu ou hebraico', 'Hindi', 'Hiri motu', 'Holandês', 'Húngaro', 'Indonésio', 'Inglês', 'Irlandês',
  'Italiano', 'Japonês', 'Libras', 'Canará', 'Khmer', 'Laociano', 'Lituano', 'Macedônio', 'Malaio', 'Malaiala',
  'Maori', 'Marata', 'Moldávio', 'Mongol', 'Ndebele', 'Nepalês', 'Sotho setentrional', 'Norueguês',
  'Polonês ou Polaco', 'Português', 'Romeno', 'Russo', 'Sueco', 'Turco', 'Vietnamita'
]

export const NIVEL = [
  'Básico', 'Médio', 'Intermediário', 'Avançado'
]

export const AREAS_INTERESSE = [
  'Administração Comercial/Vendas', 'Administração de Empresas', 'Administração Pública', 'Advocacia/Jurídica',
  'Agronomia', 'Arquitetura/Urbanismo', 'Arquivologia', 'Artes Cênicas', 'Artes Gráficas', 'Artes Plásticas', 'Atend. a cliente - Bares e Restaurantes',
  'Atend. a cliente - Telefonistas/Recepcionistas', 'Atend. a cliente - Telemarketing/Call Center', 'Atend. a cliente', 'Auditoria', 'Bancos',
  'Biblioteconomia', 'Biologia/Ciências Biológicas', 'Biomedicina', 'Ciências Sociais', 'Cinema', 'Comércio Exterior', 'Compras', 'Comunicação',
  'Construção Civil', 'Contabilidade', 'Controladoria', 'Culinária/Gastronomia', 'Desenho Industrial', 'Design de Interiores', 'Design Gráfico',
  'Ecologia/Meio Ambiente'
]

export const CODIGOS_BANCOS = [
  { label: "001", value: "Banco do Brasil S.A." },
  { label: "237", value: "Banco Bradesco S.A." },
  { label: "104", value: "Caixa Econômica Federal" },
  { label: "745", value: "Banco Citibank S.A." },
  { label: "399", value: "HSBC Bank Brasil S.A. – Banco Múltiplo" },
  { label: "341", value: "Banco Itaú S.A." },
  { label: "652", value: "Itaú Unibanco Holding S.A." },
  { label: "422", value: "Banco Safra S.A." },
  { label: "033", value: "Banco Santander (Brasil) S.A." },
  { label: "246", value: "Banco ABC Brasil S.A." },
  { label: "025", value: "Banco Alfa S.A." },
  { label: "641", value: "Banco Alvorada S.A." },
  { label: "029", value: "Banco Banerj S.A." },
  { label: "038", value: "Banco Banestado S.A." },
  { label: "000", value: "Banco Bankpar S.A." },
  { label: "740", value: "Banco Barclays S.A." },
  { label: "107", value: "Banco BBM S.A." },
  { label: "031", value: "Banco Beg S.A." },
  { label: "096", value: "Banco BM&F de Serviços de Liquidação e Custódia S.A" },
  { label: "318", value: "Banco BMG S.A." },
  { label: "752", value: "Banco BNP Paribas Brasil S.A." },
  { label: "248", value: "Banco Boavista Interatlântico S.A." },
  { label: "036", value: "Banco Bradesco BBI S.A." },
  { label: "204", value: "Banco Bradesco Cartões S.A." },
  { label: "225", value: "Banco Brascan S.A." },
  { label: "044", value: "Banco BVA S.A." },
  { label: "263", value: "Banco Cacique S.A." },
  { label: "473", value: "Banco Caixa Geral – Brasil S.A." },
  { label: "222", value: "Banco Calyon Brasil S.A." },
  { label: "040", value: "Banco Cargill S.A." },
  { label: "M08", value: "Banco Citicard S.A." },
  { label: "M19", value: "Banco CNH Capital S.A." },
  { label: "215", value: "Banco Comercial e de Investimento Sudameris S.A." },
  { label: "756", value: "Banco Cooperativo do Brasil S.A. – BANCOOB" },
  { label: "748", value: "Banco Cooperativo Sicredi S.A." },
  { label: "505", value: "Banco Credit Suisse (Brasil) S.A." },
  { label: "229", value: "Banco Cruzeiro do Sul S.A." },
  { label: "003", value: "Banco da Amazônia S.A." },
  { label: "707", value: "Banco Daycoval S.A." },
  { label: "M06", value: "Banco de Lage Landen Brasil S.A." },
  { label: "024", value: "Banco de Pernambuco S.A. – BANDEPE" },
  { label: "456", value: "Banco de Tokyo-Mitsubishi UFJ Brasil S.A." },
  { label: "214", value: "Banco Dibens S.A." },
  { label: "047", value: "Banco do Estado de Sergipe S.A." },
  { label: "037", value: "Banco do Estado do Pará S.A." },
  { label: "041", value: "Banco do Estado do Rio Grande do Sul S.A." },
  { label: "004", value: "Banco do Nordeste do Brasil S.A." },
  { label: "265", value: "Banco Fator S.A." },
  { label: "M03", value: "Banco Fiat S.A." },
  { label: "224", value: "Banco Fibra S.A." },
  { label: "626", value: "Banco Ficsa S.A." },
  { label: "394", value: "Banco Finasa BMC S.A." },
  { label: "M18", value: "Banco Ford S.A." },
  { label: "233", value: "Banco GE Capital S.A." },
  { label: "734", value: "Banco Gerdau S.A." },
  { label: "M07", value: "Banco GMAC S.A." },
  { label: "612", value: "Banco Guanabara S.A." },
  { label: "M22", value: "Honda S.A." },
  { label: "063", value: "Banco Ibi S.A. Banco Múltiplo" },
  { label: "M11", value: "Banco IBM S.A." },
  { label: "604", value: "Banco Industrial do Brasil S.A." },
  { label: "320", value: "Banco Industrial e Comercial S.A." },
  { label: "653", value: "Banco Indusval S.A." },
  { label: "630", value: "Banco Intercap S.A." },
  { label: "249", value: "Banco Investcred Unibanco S.A." },
  { label: "184", value: "Banco Itaú BBA S.A." },
  { label: "479", value: "Banco ItaúBank S.A" },
  { label: "M09", value: "Banco Itaucred Financiamentos S.A." },
  { label: "376", value: "Banco J. P. Morgan S.A." },
  { label: "074", value: "Banco J. Safra S.A." },
  { label: "217", value: "Banco John Deere S.A." },
  { label: "065", value: "Banco Lemon S.A." },
  { label: "600", value: "Banco Luso Brasileiro S.A." },
  { label: "755", value: "Banco Merrill Lynch de Investimentos S.A." },
  { label: "746", value: "Banco Modal S.A." },
  { label: "151", value: "Banco Nossa Caixa S.A." },
  { label: "045", value: "Banco Opportunity S.A." },
  { label: "623", value: "Banco Panamericano S.A." },
  { label: "611", value: "Banco Paulista S.A." },
  { label: "643", value: "Banco Pine S.A." },
  { label: "638", value: "Banco Prosper S.A." },
  { label: "747", value: "Banco Rabobank International Brasil S.A." },
  { label: "M16", value: "Banco Rodobens S.A." },
  { label: "072", value: "Banco Rural Mais S.A." },
  { label: "250", value: "Banco Schahin S.A." },
  { label: "749", value: "Banco Simples S.A." },
  { label: "366", value: "Banco Société Générale Brasil S.A." },
  { label: "637", value: "Banco Sofisa S.A." },
  { label: "464", value: "Banco Sumitomo Mitsui Brasileiro S.A." },
  { label: "M20", value: "Banco Toyota do Brasil S.A." },
  { label: "634", value: "Banco Triângulo S.A." },
  { label: "208", value: "Banco UBS Pactual S.A." },
  { label: "M14", value: "Banco Volkswagen S.A." },
  { label: "655", value: "Banco Votorantim S.A." },
  { label: "610", value: "Banco VR S.A." },
  { label: "370", value: "Banco WestLB do Brasil S.A." },
  { label: "021", value: "BANESTES S.A. Banco do Estado do Espírito Santo" },
  { label: "719", value: "Banif-Banco Internacional do Funchal (Brasil)S.A." },
  { label: "073", value: "BB Banco Popular do Brasil S.A." },
  { label: "078", value: "BES Investimento do Brasil S.A.-Banco de Investimento" },
  { label: "069", value: "BPN Brasil Banco Múltiplo S.A." },
  { label: "070", value: "BRB – Banco de Brasília S.A." },
  { label: "477", value: "Citibank N.A." },
  { label: "487", value: "Deutsche Bank S.A. – Banco Alemão" },
  { label: "751", value: "Dresdner Bank Brasil S.A. – Banco Múltiplo" },
  { label: "062", value: "Hipercard Banco Múltiplo S.A." },
  { label: "492", value: "ING Bank N.V." },
  { label: "488", value: "JPMorgan Chase Bank" },
  { label: "409", value: "UNIBANCO – União de Bancos Brasileiros S.A." },
  { label: "230", value: "Unicard Banco Múltiplo S.A." }
]


// INSERT INTO areas_interesse (nome)
// VALUES
// ('Administração Comercial/Vendas'),
// ('Administração de Empresas'),
// ('Administração Pública'),
// ('Advocacia/Jurídica'),
// ('Agronomia'),
// ('Arquitetura/Urbanismo'),
// ('Arquivologia'),
// ('Artes Cênicas'),
// ('Artes Gráficas'),
// ('Artes Plásticas'),
// ('Atend. a cliente - Bares e Restaurantes'),
// ('Atend. a cliente - Telefonistas/Recepcionistas'),
// ('Atend. a cliente - Telemarketing/Call Center'),
// ('Atend. a cliente'),
// ('Auditoria'),
// ('Bancos'),
// ('Biblioteconomia'),
// ('Biologia/Ciências Biológicas'),
// ('Biomedicina'),
// ('Ciências Sociais'),
// ('Cinema'),
// ('Comércio Exterior'),
// ('Compras'),
// ('Comunicação'),
// ('Construção Civil'),
// ('Contabilidade'),
// ('Controladoria'),
// ('Culinária/Gastronomia'),
// ('Desenho Industrial'),
// ('Design de Interiores'),
// ('Design Gráfico'),
// ('Ecologia/Meio Ambiente'),
// ('Economia'),
// ('Enfermagem'),
// ('Engenharia de Alimentos'),
// ('Engenharia Civil'),
// ('Engenharia Eletrônica'),
// ('Engenharia Eletrotécnica'),
// ('Engenharia Mecânica'),
// ('Engenharia Metalúrgica e de Materiais'),
// ('Engenharia de Minas'),
// ('Engenharia de Produção'),
// ('Engenharia Química'),
// ('Engenharia - Outras'),
// ('Ensino Superior e Pesquisa'),
// ('Ensino - Outros'),
// ('Entretenimento'),
// ('Esportes'),
// ('Estética'),
// ('Farmácia'),
// ('Filosofia'),
// ('Finanças'),
// ('Fiscal'),
// ('Física'),
// ('Fisioterapia'),
// ('Fonoaudiologia'),
// ('Geografia'),
// ('Geologia'),
// ('Gestão Empresarial'),
// ('História'),
// ('Hotelaria'),
// ('Informática/T.I.'),
// ('Internet'),
// ('Jornalismo'),
// ('Letras'),
// ('Logística'),
// ('Manutenção Industrial'),
// ('Manutenção Predial'),
// ('Marketing'),
// ('Matemática/Estatística'),
// ('Medicina/Hospitalar'),
// ('Meteorologia'),
// ('Mineração'),
// ('Moda'),
// ('Museologia'),
// ('Música'),
// ('Nutrição'),
// ('Oceanografia'),
// ('Odontologia'),
// ('Organização de Eventos/Produção Cultural'),
// ('Organização e Métodos'),
// ('Pesquisa de Mercado'),
// ('Petrolífera'),
// ('Produção/Fabricação'),
// ('Propaganda'),
// ('Psicologia'),
// ('Qualidade'),
// ('Química'),
// ('Radialismo e Televisão'),
// ('Recursos Humanos'),
// ('Relações Internacionais'),
// ('Relações Públicas'),
// ('Secretariado'),
// ('Segurança e Saúde no Trabalho'),
// ('Segurança Patrimonial'),
// ('Seguros'),
// ('Serviço Social'),
// ('Serviços Administrativos'),
// ('Serviços Domésticos'),
// ('Serviços Especializados - Açougue'),
// ('Serviços Especializados - Padaria/Confeitaria'),
// ('Serviços Especializados - Peixaria'),
// ('Serviços Gerais'),
// ('Serviços Técnicos - Elétricos'),
// ('Serviços Técnicos - Eletrônicos'),
// ('Serviços Técnicos - Mecânicos'),
// ('Serviços Técnicos - Outros'),
// ('Suprimentos'),
// ('Telecomunicações'),
// ('Terapia Ocupacional'),
// ('Terceiro Setor/Responsabilidade Social'),
// ('Tradução/Interpretação'),
// ('Transporte Aéreo'),
// ('Transporte Marítimo'),
// ('Transporte Terrestre'),
// ('Tributária'),
// ('Turismo'),
// ('Vendas'),
// ('Vendas - Varejo'),
// ('Vendas Técnicas'),
// ('Veterinária'),
// ('Web Design'),
// ('Zoologia'),
// ('Zootecnia'),
// ('Marketing digital'),
// ('Outros');
