import React, { useEffect, useState } from 'react';
import { PageHeader, Form, Input, Radio, Select, Checkbox, Button, Row, Col, Slider, DatePicker, Modal, Spin, AutoComplete } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { signOutRequest } from '../../redux/modules/auth/actions';

// CONFIG MOMENT LOCALE BRAZIL
import moment from 'moment'
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR'

import {
  CONHECIMENTOS_DIVERSOS_INFORMATICA,
  MANEQUIM_BLUSA,
  TIPO_CABELO,
  TOM_CABELO,
  TOM_PELE,
  COR_OLHOS,
  UF,
  ORGAO_EXPEDIDOR,
  NIVEL,
  IDIOMAS, AREAS_INTERESSE, CODIGOS_BANCOS
} from '../../helpers/helpers';
import api from '../../services/api';
import history from '../../services/history';
import Label from '../../components/Label';


moment.locale('pt-br');

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const { TextArea } = Input;
const { Option, OptGroup } = Select;

export default function PessoaFisica({ location }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user)
  const auth = useSelector(state => state.auth.signed)

  const [form] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [idsModels, setIdsModels] = useState({
    pessoa_id: null,
    pessoa_fisica_id: null,
    rede_social_id: null,
    endereco_id: null,
    pne_id: null,
    filiacao_id: null,
    observacoes_pessoa_fisica_id: null,
    areas_interesse_id: null,
    formacao_id: null,
    idiomas_id: null,
    descricao_profissional_id: null,
    experiencia_profissional_id: null,
    dados_bancarios_id: null,
    conhecimentos_diversos_id: null,
    rg_id: null,
    informacao_pessoal_id: null,
    disponibilidade_id: null,
    descricao_profissional_id: null
  })

  const [areasInteresses, setAreasInteresses] = useState([])
  const [primeiroEmprego, setPrimeiroEmprego] = useState(true)
  const [participarVagas, setParticiparVagas] = useState(false)
  const [possuiCarro, setPossuiCarro] = useState(false)
  const [possuiMoto, setPossuiMoto] = useState(false)
  const [possuiCNH, setPossuiCNH] = useState(false)
  const [dispViagem, setDispViagem] = useState(false)
  const [estadia, setEstadia] = useState(false)
  const [cdBanco, setCdBanco] = useState('')
  const [codigosBanco, setCodigosBanco] = useState(CODIGOS_BANCOS)

  const [cep, setCep] = useState('')
  const [, setLogradouro] = useState('')
  // const [numero, setNumero] = useState('')
  const [, setCidade] = useState('')
  const [, setBairro] = useState('')
  const [, setEstado] = useState('')

  // PNE
  const [valuePNE, setValuePNE] = useState([])

  // DISPONIBILIDADE DE HORARIO
  const [valuesDisponibilidadeHorario, setDisponibilidadeHorario] = useState([])

  const [dataNascimento, setDataNascimento] = useState(null)
  const [dataAdmissao, setDataAdmissao] = useState(null)
  const [dataDemissao, setDataDemissao] = useState(null)

  const [sexo, setSexo] = useState('')

  const [altura, setAltura] = useState(1.5)
  const [peso, setPeso] = useState(50)

  const { id } = useParams();

  useEffect(() => {
    console.log('LOCATION ', location)
    if (location?.state === 'VIEW') {
      setIsDisabled(true)
    }

    getAreasInteresse()

    if (id) {
      console.log('ID ', id)
      getPessoa()
    }

    async function getAreasInteresse() {
      try {
        const response = await api.get('/api/area_interesse')

        setAreasInteresses(response.data)
      } catch (err) {
        console.log('ERROR CARREGAR AREAS INTERESSE ', err)
      }
    }
  }, [])

  function logout() {
    dispatch(signOutRequest())
  }

  async function onFinish(values) {
    const areaInteresseMapper = values?.area_interesse?.map(area => (
      Number(area?.split('-')[0])
    ))

    // console.log('NOVA AREA INTERESSE  ', areaInteresseMapper)

    setIsLoading(true)
    if (!sexo) {
      return Modal.error({
        title: 'Sexo é obrigatório.'
      })
    }

    if (primeiroEmprego == null) {
      return Modal.error({
        title: 'Primeiro emprego é obrigatório.'
      })
    }

    if (participarVagas == null) {
      return Modal.error({
        title: 'Vagas para eventos é obrigatório.'
      })
    }

    values.cep = Number(values.cep?.replace(/\D/g, ''))
    values.celular = Number(values.celular?.replace(/\D/g, ''))
    values.rg = Number(values.rg?.replace(/\D/g, ''))
    values.cpf = values.cpf?.replace(/\D/g, '')
    values.numero = Number(values.numero?.replace(/\D/g, ''))
    values.cd_banco = Number(values.cd_banco?.replace(/\D/g, ''))
    values.operacao = Number(values.operacao?.replace(/\D/g, ''))

    values.num_sapato = Number(values.num_sapato?.replace(/\D/g, ''))
    values.manequim_calca = Number(values.manequim_calca?.replace(/\D/g, ''))

    values.data_nascimento = dataNascimento
    values.data_admissao = dataAdmissao
    values.data_demissao = dataDemissao

    const pessoa_fisica = {
      nome: values.nome,
      cpf: values.cpf,
      data_nascimento: values.data_nascimento,
      pais_origem: values.pais_origem,
      naturalidade: values.naturalidade,
      sexo,
      estado_civil: values.estado_civil,
      celular: values.celular,
      email: values.email,
    }

    const filiacao = {
      pai: values.pai,
      mae: values.mae,
    }

    const rg = {
      numero: values?.rg, // TIPO NUMERIC
      orgao: values?.orgao,
      uf: values?.uf, // CHAR 2
      // data_expedicao: ,  // FALTA DEFINIR
    }

    const pne = {
      auditivo: valuePNE?.includes('Auditiva'),
      fala: valuePNE?.includes('Fala'),
      mental: valuePNE?.includes('Mental'),
      motora: valuePNE?.includes('Motora'),
      visual: valuePNE?.includes('Visual')
    }

    const endereco = {
      cep: values.cep,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      cidade: values.cidade,
      bairro: values.bairro,
      estado: values.estado,
    }

    const disponibilidade = {
      comercial: valuesDisponibilidadeHorario?.includes('Comercial'),
      manha: valuesDisponibilidadeHorario?.includes('Manhã'),
      tarde: valuesDisponibilidadeHorario?.includes('Tarde'),
      noite: valuesDisponibilidadeHorario?.includes('Noite'),
      fim_de_semana: valuesDisponibilidadeHorario?.includes('Fim de semana')
    }

    const descricao_profissional = {
      primeiro_emprego: primeiroEmprego,
      pontos_fortes: values.pontos_fortes,
      pontos_melhorias: values.pontos_melhorias
    }

    const experiencia_profissional = {
      nome_empresa: values.nome_empresa,
      data_admissao: values.data_admissao,
      data_demissao: values.data_demissao,
      cargo_funcao: values.cargo_funcao,
      ultimo_salario: values.ultimo_salario,
      desc_atividade: values.desc_atividade,
      motivo_desligamento: values.motivo_desligamento
    }

    const redes_sociais = {
      facebook: values.facebook,
      twitter: values.twitter,
      linkedin: values.linkedin,
      instagram: values.instagram,
      youtube: values.youtube
    }

    const dados_bancarios = {
      tipo_conta: values.tipo_conta, // varchar
      cd_banco: values.cd_banco, // numeric
      nome_banco: values.nome_banco, // varchar
      operacao: values.operacao, // numeric
      favorecido: values.favorecido, // varchar
      agencia: values.agencia, // varchar
      digito_agencia: values.digito_agencia, // varchar
      conta: values.conta, // varchar
      digito_conta: values.digito_conta // varchar
    }

    const informacao_pessoal = {
      altura, // numeric
      peso, // numeric
      num_sapato: values.num_sapato, // numeric
      manequim_calca: values.manequim_calca, // numeric
      manequim_blusa: values.manequim_blusa, // char(2)
      cor_olhos: values.cor_olhos, // varchar
      tom_cabelo_atual: values.tom_cabelo_atual, // varchar
      tom_cabelo_natural: values.tom_cabelo_natural, // varchar
      tipo_cabelo: values.tipo_cabelo, // varchar
      tom_pele: values.tom_pele, // varchar

      oculos: values.caracteristicas?.includes('oculos'), // boolean
      aparelho: values.caracteristicas?.includes('aparelho'), // boolean
      piercing: values.caracteristicas?.includes('piercing'), // boolean
      tatuagem: values.caracteristicas?.includes('tatuagem'), // boolean
    }

    const observacoes_pessoa_fisica = {
      participar_vagas_emprego: participarVagas,
      possui_cnh: !possuiCNH ? false : true, // boolean
      disp_viagem: !dispViagem ? false : true, // boolean
      estadia_outro_lugar: !estadia ? false : true, // boolean
      possui_carro: !possuiCarro ? false : true, // boolean
      possui_moto: !possuiMoto ? false : true, // boolean
    }

    const veiculos = []
    if (possuiCarro) {
      veiculos.push(
        {
          tipo_veiculo: 'C',
          marca: values.marca_carro,
          ano: values.ano_carro
        }
      )
    }

    if (possuiMoto) {
      veiculos.push(
        {
          tipo_veiculo: 'M',
          marca: values.marca_moto,
          ano: values.ano_moto
        },
      )
    }

    console.log('PESSOAS FISICA ', pessoa_fisica)
    console.log('FILIACAO ', filiacao)
    console.log('PNE ', pne)
    console.log('ENDERECO ', endereco)
    console.log('RG ', rg)

    console.log('DESCRICAO PROF ', descricao_profissional)
    console.log('DISPONIBILIDADE HORARIO', disponibilidade)
    console.log('EXPERIENCIA PROF', experiencia_profissional)

    console.log('REDES SOCIAIS ', redes_sociais)
    console.log('DADOS BANCÁRIOS ', dados_bancarios)

    console.log('INFORMACOES PESSOAIS ', informacao_pessoal)
    console.log('OBSERVAÇÕES ', observacoes_pessoa_fisica)
    console.log('VEICULOS ', veiculos)

    console.log('DADOS ', values)

    console.log('IDS MODEL ', idsModels)

    try {
      const body = {
        pessoa_fisica,
        filiacao,
        rg,
        pne,
        endereco,
        disponibilidade,
        descricao_profissional,
        experiencia_profissional,
        redes_sociais,
        dados_bancarios,
        informacao_pessoal,
        observacoes_pessoa_fisica,
        veiculos,
        areas_interesse: areaInteresseMapper,
        formacao: values.formacao,
        idiomas: values.idiomas,
        conhecimentos_diversos: values.conhecimentos_diversos,
        ids_models: idsModels
      }

      let response
      id
        ? response = await api.put(`/api/atualizar/pessoa_fisica/${id}`, body)
        : response = await api.post('/api/cadastro/pessoa_fisica', body)

      console.log('RESPONSE ', response)
      if (response.status === 201) {
        setIsLoading(false)
        return Modal.success({
          title: 'Registro salvo com sucesso!',
          onOk: () => {
            setTimeout(() => {
              document.getElementById("pessoa_fisica_nome").focus()

              history.go()
            }, 300);
          },
        })
      } else if (response.status === 200) {
        setIsLoading(false)
        return Modal.success({
          title: 'Registro atualizado com sucesso!',
          onOk: () => history.push('/dashboard/admin')
        })
      } else {
        setIsLoading(false)
        return Modal.error({
          title: 'Não foi possível concluir seu cadastro, tente novamente mais tarde.'
        })
      }
    } catch (error) {
      setIsLoading(false)
      return Modal.error({
        title: 'Não foi possível concluir seu cadastro, tente novamente mais tarde.'
      })
    }


  }

  // RECUPERAR PESSOA
  async function getPessoa() {
    try {
      const response = await api.get(`/api/pessoa-fisica/${id}`)

      if (response.status === 200) {
        const pessoa = response.data.pessoa
        const pessoa_fisica = response.data.pessoa_fisica
        const endereco = response.data.pessoa_fisica?.endereco
        const pne = response.data.pessoa_fisica?.pne
        const rg = response.data.pessoa_fisica?.rg
        const filiacao = response.data.pessoa_fisica?.filiacao
        const informacao_pessoal = response.data.pessoa_fisica?.informacao_pessoal
        const observacoes_pessoa_fisica = response.data.observacoes_pessoa_fisica
        const dados_bancarios = response.data.dados_bancarios
        const veiculos = response.data.veiculos
        const descricao_profissional = response.data.descricao_profissional
        const experiencia_profissional = response.data.experiencia_profissional
        const formacao = response.data?.formacao
        const idiomas = response.data?.idiomas
        const conhecimentos_diversos = response.data?.conhecimentos_diversos
        const areas_interesse = response.data?.areas_interesse

        setIdsModels({
          pessoa_id: pessoa?.id,
          pessoa_fisica_id: pessoa_fisica?.id,
          rede_social_id: pessoa.rede_social?.id,
          endereco_id: endereco?.id,
          pne_id: pne?.id,
          filiacao_id: filiacao?.id,
          observacoes_pessoa_fisica_id: observacoes_pessoa_fisica?.id,
          areas_interesse_id: areasInteresses?.id,
          formacao_id: formacao?.id,
          idiomas_id: idiomas?.id,
          descricao_profissional_id: descricao_profissional?.id,
          experiencia_profissional_id: experiencia_profissional?.id,
          dados_bancarios_id: dados_bancarios?.id,
          conhecimentos_diversos_id: conhecimentos_diversos?.id,
          rg_id: rg?.id,
          informacao_pessoal_id: informacao_pessoal?.id,
          disponibilidade_id: descricao_profissional?.disponibilidade?.id,
          descricao_profissional_id: descricao_profissional?.id
        })

        // MONTAGEM FORMACAO
        const formacaoTratado = formacao.map(form => ({
          instituicao: form?.instituicao,
          grau_escolaridade: form?.grau,
          curso: form?.curso
        }))

        // MONTAGEM DE AREAS DE INTERESSE
        const areasInteresseMapped = trataAreasInteresse(areas_interesse)
        console.log('AREAS INTERESSE TRATATO   ', areasInteresseMapped)
        console.log('FORMACAO TRATADO   ', formacaoTratado)
        console.log('DADOS RECUPERADOS', response.data)

        setParticiparVagas(observacoes_pessoa_fisica?.participar_vagas_emprego)
        setPrimeiroEmprego(descricao_profissional?.primeiro_emprego)
        setPossuiCNH(observacoes_pessoa_fisica?.possui_cnh)
        setDispViagem(observacoes_pessoa_fisica?.disp_viagem)
        setEstadia(observacoes_pessoa_fisica?.estadia_outro_lugar)
        setPossuiCarro(observacoes_pessoa_fisica?.possui_carro)
        setPossuiMoto(observacoes_pessoa_fisica?.possui_moto)
        setSexo(pessoa_fisica?.sexo)
        setAltura(Number(pessoa_fisica?.informacao_pessoal?.altura))

        pessoa_fisica?.data_nascimento !== null
          ? setDataNascimento(moment(pessoa_fisica?.data_nascimento))
          : setDataNascimento(null)

        experiencia_profissional?.data_admissao !== null
          ? setDataAdmissao(moment(experiencia_profissional?.data_admissao))
          : setDataAdmissao(null)

        experiencia_profissional?.data_demissao !== null
          ? setDataDemissao(moment(experiencia_profissional?.data_demissao))
          : setDataDemissao(null)


        if (veiculos) {
          let tipoCarro = veiculos.filter(v => v.tipo_veiculo === "C")
          let tipoMoto = veiculos.filter(v => v.tipo_veiculo === "M")

          form.setFieldsValue({
            marca_carro: tipoCarro[0]?.marca,
            ano_carro: tipoCarro[0]?.ano,

            marca_moto: tipoMoto[0]?.marca,
            ano_moto: tipoMoto[0]?.ano,
          })
        }
        form.setFieldsValue({
          ...pessoa,
          carro: observacoes_pessoa_fisica?.possui_carro,
          ...pessoa_fisica,
          data_nascimento: pessoa_fisica?.data_nascimento
            ? moment(pessoa_fisica?.data_nascimento)
            : null,
          ...filiacao,
          ...rg,
          rg: rg?.numero,
          ...pne,
          ...descricao_profissional,
          ...experiencia_profissional,
          data_admissao: experiencia_profissional?.data_admissao
            ? moment(experiencia_profissional?.data_admissao)
            : null,
          data_demissao: experiencia_profissional?.data_demissao
            ? moment(experiencia_profissional?.data_demissao)
            : null,
          primeiro_emprego: descricao_profissional?.primeiro_emprego,
          disp_horario: [
            descricao_profissional?.disponibilidade?.comercial ? 'Comercial' : '',
            descricao_profissional?.disponibilidade?.manha ? 'Manhã' : '',
            descricao_profissional?.disponibilidade?.tarde ? 'Tarde' : '',
            descricao_profissional?.disponibilidade?.noite ? 'Noite' : '',
            descricao_profissional?.disponibilidade?.fim_de_semana ? 'Fim de semana' : '',
          ],
          pne: [
            pne?.auditivo ? 'Auditiva' : '',
            pne?.fala ? 'Fala' : '',
            pne?.mental ? 'Mental' : '',
            pne?.motora ? 'Motoro' : '',
            pne?.visual ? 'Visual' : '',
          ],

          ...informacao_pessoal,
          altura: Number(informacao_pessoal?.altura),
          peso: Number(informacao_pessoal?.peso),
          caracteristicas: [
            informacao_pessoal?.oculos ? 'oculos' : '',
            informacao_pessoal?.tatuagem ? 'tatuagem' : '',
            informacao_pessoal?.piercing ? 'piercing' : '',
            informacao_pessoal?.aparelho ? 'aparelho' : '',
          ],

          area_interesse: areasInteresseMapped,

          formacao: formacaoTratado,
          idiomas,
          conhecimentos_diversos,

          cep: endereco?.cep,
          logradouro: endereco?.logradouro,
          numero: String(endereco?.numero),
          complemento: endereco?.complemento,
          cidade: endereco?.cidade,
          bairro: endereco?.bairro,
          estado: endereco?.estado,

          cnpj_titular: dados_bancarios?.cnpj_titular,
          cpf_titular: dados_bancarios?.cpf_titular,
          tipo_conta: dados_bancarios?.tipo_conta,
          tipo_pessoa: dados_bancarios?.tipo_pessoa,
          cd_banco: String(dados_bancarios?.cd_banco),
          nome_banco: dados_bancarios?.nome_banco,
          operacao: String(dados_bancarios?.operacao),
          favorecido: dados_bancarios?.favorecido,
          agencia: dados_bancarios?.agencia,
          digito_agencia: dados_bancarios?.digito_agencia,
          conta: dados_bancarios?.conta,
          digito_conta: dados_bancarios?.digito_conta,

          facebook: pessoa.rede_social.facebook,
          twitter: pessoa.rede_social.twitter,
          instagram: pessoa.rede_social.instagram,
          linkedin: pessoa.rede_social.linkedin,
          youtube: pessoa.rede_social.youtube,
        })
      }
    } catch (err) {
      if (user && err.response?.status === 401) {
        return Modal.error({
          title: 'Sessão expirada!',
          content: 'Por favor, se autentique novamente.',
          onOk: () => {
            logout()
          },
        })
      }
    }
  }

  // BLUR
  async function getFromCEP() {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    if (response.status === 200) {
      form.setFieldsValue({
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
        logradouro: response.data.logradouro,

      })
      setLogradouro(response.data.logradouro)
      setBairro(response.data.bairro)
      setCidade(response.data.localidade)
      setEstado(response.data.uf)
    }
  }

  function trataAreasInteresse(areas_interesse) {
    const retorno = areas_interesse?.map(area => {
      let tratativa = `${area?.area_interesse?.id}-${area?.area_interesse?.nome}`
      return tratativa
    })
    return retorno
  }

  function onChangeCep(value) {
    const cepRegex = value?.replace(/\D/g, '')
    setCep(cepRegex)
  }

  function onChangeDataNascimento(date, dateString) {
    console.log(date, dateString);
    console.log(date?.format('YYYY/MM/DD'))
    setDataNascimento(date?.format('YYYY-MM-DD'))
  }

  function onChangePNE(checkedValues) {
    console.log('checked = ', checkedValues);
    setValuePNE(checkedValues)
  }

  function onChangeDisponibilidade(checkedValues) {
    console.log('checked = ', checkedValues);
    setDisponibilidadeHorario(checkedValues)
  }

  function onChangeSexo(value) {
    console.log('checked = ', value.target?.value);
    setSexo(value.target?.value)
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <PageHeader
              className="site-page-header"
              title="TRABALHE CONOSCO"
              subTitle="Pessoa Física"
              style={{ paddingLeft: 2, paddingRight: 12, textAlign: 'center' }}
            />
          </div>
        </div>
      </div>

      {/* SESSÃO 1 */}

      <div className="px-3" style={{ marginTop: 50, backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">INFORMAÇÕES</h2>
            </div>
            <div className="col-md-6">

              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="nome"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Nome:" style={{ textTransform: 'uppercase' }} disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Digite um e-mail válido!',
                    },
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="E-mail:" style={{ textTransform: 'lowercase' }} disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="cpf"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="111.111.111-11" placeholder="CPF:"
                    onBlur={async e => {
                      const numberCPF = e.target?.value.replace(/\D/g, '')
                      console.log('nbm cpf ', numberCPF)
                      if (numberCPF?.toString().length > 1 && numberCPF?.toString().length < 11) {
                        form.setFieldsValue({
                          cpf: ''
                        })

                        return Modal.error({
                          title: 'CPF inválido.'
                        })
                      }

                      try {
                        const response = await api.post('api/verifica_cpf', {
                          cpf: numberCPF
                        })
                        if (response.status === 400) {
                          form.setFieldsValue({
                            cpf: ''
                          })

                          Modal.error({
                            title: 'CPF já cadastrado.'
                          })
                        }
                      } catch (err) {
                        if (err.response.status === 400) {
                          form.setFieldsValue({
                            cpf: ''
                          })

                          Modal.error({
                            title: 'CPF já cadastrado.'
                          })
                        }
                      }
                    }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="(11) 11111-1111"
                    placeholder="Celular:"
                    minLength={11}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="sexo"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Este campo é obrigatório',
                  //   },
                  // ]}
                  style={{ marginTop: 15 }}
                >
                  <label htmlFor="sexo"><b>Sexo</b></label> <br />
                  <Radio.Group name="sexo" value={sexo} onChange={onChangeSexo} disabled={isDisabled}>
                    <Radio value={'M'}>Masculino</Radio>
                    <Radio value={'F'}>Feminino</Radio>
                  </Radio.Group>
                </Form.Item>

                <Label title="Data de nascimento" style={{ marginTop: 15 }} />
                <Form.Item
                  className="custom-margin-form-item"
                  name="data_nascimento"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Este campo é obrigatório',
                //   },
                // ]}
                >
                  <DatePicker
                    className="calendar-ant"
                    locale={locale}
                    onChange={onChangeDataNascimento}
                    format="DD/MM/YYYY"
                    size="large"
                    placeholder=""
                    value={dataNascimento}
                    disabled={isDisabled}
                  />
                </Form.Item>
                <Form.Item
                  className="custom-margin-form-item"
                  name="rg"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                  style={{ marginTop: 15 }}
                >
                  <Input placeholder="RG:" style={{ textTransform: 'uppercase' }} disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="orgao"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Select className="size_select" placeholder="Órgão expedidor" disabled={isDisabled}>
                    {ORGAO_EXPEDIDOR && ORGAO_EXPEDIDOR.map(i => (
                      <Option key={i} value={i} title={i}>
                        {i}
                      </Option>
                    ))
                    }

                  </Select>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="uf"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Select className="size_select" placeholder="UF" disabled={isDisabled}>

                    {UF && UF.map(i => (
                      <Option key={i} value={i} title={i}>
                        {i}
                      </Option>
                    ))
                    }

                  </Select>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="pai"
                >
                  <Input
                    placeholder="Nome do pai:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="mae"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Nome da mãe:" style={{ textTransform: 'uppercase' }} disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="estado_civil"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Select placeholder="Estado civil" className="size_select" disabled={isDisabled}>
                    <Option value="Solteiro">
                      Solteiro (a)
                    </Option>
                    <Option value="Casado">
                      Casado (a)
                    </Option>
                    <Option value="Separado">
                      Separado (a) Judicialmente
                    </Option>
                    <Option value="União Estável">
                      União Estável
                    </Option>
                    <Option value="Viúvo">
                      Viúvo (a)
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="naturalidade"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Naturalidade:" style={{ textTransform: 'uppercase' }} disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="pais_origem"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="País de origem:" style={{ textTransform: 'uppercase' }} disabled={isDisabled} />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">OBSERVAÇÕES</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="cnh"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Este campo é obrigatório',
                //   },
                // ]}
                >
                  <label htmlFor="disp_viagem"><b>Possui carteira de habilitação(cnh):</b></label> <br />
                  <Radio.Group
                    value={possuiCNH}
                    onChange={value => { setPossuiCNH(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="disp_viagem"
                >
                  <label htmlFor="disp_viagem"><b>Disponiblidade para viagem?</b></label> <br />
                  <Radio.Group
                    value={dispViagem}
                    onChange={value => { setDispViagem(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="estadia"
                >
                  <label htmlFor="estadia"><b>Possui estadia em outro lugar?</b></label> <br />
                  <Radio.Group
                    value={estadia}
                    onChange={value => { setEstadia(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="carro"
                >
                  <label htmlFor="carro"><b>Possui carro?</b></label> <br />
                  <Radio.Group
                    value={possuiCarro}
                    onChange={value => { setPossuiCarro(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {possuiCarro === true &&
                  <>
                    <Form.Item
                      className="custom-margin-form-item"
                      name="marca_carro"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório',
                        },
                      ]}
                    >
                      <Input placeholder="Modelo:" disabled={isDisabled} />
                    </Form.Item>

                    <Form.Item
                      className="custom-form-item"
                      name="ano_carro"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório',
                        },
                      ]}
                    >
                      <Input placeholder="Ano:" disabled={isDisabled} />
                    </Form.Item>
                  </>
                }

                <Form.Item
                  className={possuiMoto === true ? 'custom-margin-form-item' : 'custom-form-item'}
                  name="moto"
                >
                  <label htmlFor="moto"><b>Possui moto?</b></label> <br />
                  <Radio.Group
                    value={possuiMoto}
                    onChange={value => { setPossuiMoto(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {possuiMoto === true &&
                  <>
                    <Form.Item
                      className="custom-margin-form-item"
                      name="marca_moto"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório',
                        },
                      ]}
                    >
                      <Input placeholder="Modelo:" disabled={isDisabled} />
                    </Form.Item>

                    <Form.Item
                      className="custom-form-item"
                      name="ano_moto"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório',
                        },
                      ]}
                    >
                      <Input placeholder="Ano:" disabled={isDisabled} />
                    </Form.Item>
                  </>
                }

              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">ÁREA DE INTERESSE</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <div>
                  <Form.Item
                    className="custom-form-item"
                    name="area_interesse"
                    style={{ height: '100%' }}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Área de interesse"
                      style={{ minHeight: '100%' }}
                      disabled={isDisabled}
                    >
                      {areasInteresses && areasInteresses.map(area => (

                        <Option key={area.id} value={`${area.id}-${area.nome}`} title={area.nome}>
                          {area.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* SESSÃO 2 */}
      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">ENDEREÇO</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="cep"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="11.111-111" placeholder="CEP:"
                    onBlur={getFromCEP} onChange={e => onChangeCep(e.target.value)}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="logradouro"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Logradouro:" disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="numero"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="1111111111"
                    placeholder="Número:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="complemento"
                >
                  <Input
                    placeholder="Complemento:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="cidade"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Cidade:" disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="bairro"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Bairro:" disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="estado"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Estado:" disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>

      </div>

      {/* SESSÃO 3 */}
      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">PNE</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="pne"
                >
                  <Checkbox.Group
                    onChange={onChangePNE}
                    disabled={isDisabled}
                  >
                    <label htmlFor="pne"><b>Possui deficiências?</b></label>
                    <Row>
                      <Col span={8}>
                        <Checkbox value="Auditiva" style={{ lineHeight: '32px' }}>
                          Auditiva
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Fala" style={{ lineHeight: '32px' }}>
                          Fala
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Mental" style={{ lineHeight: '32px' }}>
                          Mental
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Motora" style={{ lineHeight: '32px' }}>
                          Motora
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Visual" style={{ lineHeight: '32px' }}>
                          Visual
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>

      </div>


      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">REDES SOCIAIS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="facebook"
                >
                  <Input
                    placeholder="Facebook:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="twitter"
                >
                  <Input
                    placeholder="Twitter:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="linkedin"
                >
                  <Input
                    placeholder="Linkedin:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="instagram"
                >
                  <Input
                    placeholder="Instagram:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="youtube"
                >
                  <Input
                    placeholder="Youtube:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">CURSO E FORMAÇÃO</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.List name="formacao">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map(field => (
                          <div key={field.key}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'grau_escolaridade']}
                              fieldKey={[field.fieldKey, `grau_escolaridade${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                name="nivelEscolaridade"
                                id="nivelEscolaridade"
                                placeholder="Grau de escolaridade"
                                disabled={isDisabled}
                              >
                                <OptGroup label="Ensino Pre - Fundamental">
                                  <Option title="Ensino Pre - Fundamental Completo" value="Ensino Pre - Fundamental Completo">Ensino Pre - Fundamental Completo</Option>
                                  <Option title="Ensino Pre - Fundamental Cursando" value="Ensino Pre - Fundamental Cursando">Ensino Pre - Fundamental Cursando</Option>
                                  <Option title="Ensino Pre - Fundamental Incompleto" value="Ensino Pre - Fundamental Incompleto">Ensino Pre - Fundamental Incompleto</Option>
                                </OptGroup>
                                <OptGroup label="Ensino Fundamental">
                                  <Option title="Ensino Fundamental Completo" value="Ensino Fundamental Completo">Ensino Fundamental Completo</Option>
                                  <Option title="Ensino Fundamental Cursando" value="Ensino Fundamental Cursando">Ensino Fundamental Cursando</Option>
                                  <Option title="Ensino Fundamental Incompleto" value="Ensino Fundamental Incompleto">Ensino Fundamental Incompleto</Option>
                                  <Option title="Técnico" value="Técnico">Técnico</Option>
                                  <Option title="Técnico Cursando" value="Técnico Cursando">Técnico Cursando</Option>
                                </OptGroup>
                                <OptGroup label="Ensino Médio ">
                                  <Option title="Ensino Médio Completo" value="Ensino Médio Completo">Ensino Médio Completo</Option>
                                  <Option title="Ensino Médio Cursando" value="Ensino Médio Cursando">Ensino Médio Cursando</Option>
                                  <Option title="Ensino Médio Incompleto" value="Ensino Médio Incompleto">Ensino Médio Incompleto</Option>
                                </OptGroup>
                                <OptGroup label="Ensino Superior ">
                                  <Option title="Ensino Superior Cursando" value="Ensino Superior Cursando">Ensino Superior Cursando</Option>
                                  <Option title="Ensino Superior Completo" value="Ensino Superior Completo">Ensino Superior Completo</Option>
                                  <Option title="Ensino Superior Incompleto" value="Ensino Superior Incompleto">Ensino Superior Incompleto</Option>
                                </OptGroup>
                                <OptGroup label="Pós Graduação ">
                                  <Option title="Pós Graduação Cursando" value="Pós Graduação Cursando">Pós Graduação Cursando</Option>
                                  <Option title="Pós Graduação Completa" value="Pós Graduação Completa">Pós Graduação Completa</Option>
                                  <Option title="Pós Graduação Incompleta" value="Pós Graduação Incompleta">Pós Graduação Incompleta</Option>
                                </OptGroup>
                                <OptGroup label="Mestrado">
                                  <Option title="Mestrado Completo" value="Mestrado Completo">Mestrado Completo</Option>
                                  <Option title="Mestrado Cursando" value="Mestrado Cursando">Mestrado Cursando</Option>
                                  <Option title="Mestrado Incompleto" value="Mestrado Incompleto">Mestrado Incompleto</Option>
                                </OptGroup>
                                <OptGroup label="Doutorado">
                                  <Option title="Doutorado Cursando" value="Doutorado Cursando">Doutorado Cursando</Option>
                                  <Option title="Doutorado Completo" value="Doutorado Completo">Doutorado Completo</Option>
                                  <Option title="Doutorado Incompleto" value="Doutorado Incompleto">Doutorado Incompleto</Option>
                                </OptGroup>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, 'instituicao']}
                              fieldKey={[field.fieldKey, `instituicao${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Input
                                placeholder="Instituição de ensino:"
                                style={{ textTransform: 'uppercase' }}
                                disabled={isDisabled}
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, 'curso']}
                              fieldKey={[field.fieldKey, `curso${field.key}`]}
                              className="custom-margin-form-item"
                            >
                              <Input
                                placeholder="Curso:"
                                style={{ textTransform: 'lowercase' }}
                                disabled={isDisabled}
                              />
                            </Form.Item>


                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 20
                              }}
                            >
                              <MinusCircleOutlined
                                disabled={isDisabled}
                                readOnly={isDisabled}
                                onClick={() => {
                                  remove(field.name);
                                }}
                                style={{ marginRight: 5 }}
                              />
                                Remover
                            </div>
                          </div>
                        ))}

                        <Form.Item
                          className='custom-margin-form-item'
                        >
                          <Button
                            disabled={isDisabled}
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> Adicionar
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
                {/*  */}
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">IDIOMAS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >

                <Form.List name="idiomas">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map(field => (
                          <div key={field.key}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'idioma']}
                              fieldKey={[field.fieldKey, `idioma${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                placeholder="Idioma:"
                                disabled={isDisabled}
                              >
                                {IDIOMAS && IDIOMAS.map(i => (
                                  <Option key={i} value={i} title={i}>
                                    {i}
                                  </Option>
                                ))
                                }
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, 'leitura']}
                              fieldKey={[field.fieldKey, `leitura${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                placeholder="Leitura:"
                                disabled={isDisabled}
                              >
                                {NIVEL && NIVEL.map(i => (
                                  <Option key={i} value={i} title={i}>
                                    {i}
                                  </Option>
                                ))
                                }
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, 'escrita']}
                              fieldKey={[field.fieldKey, `escrita${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                placeholder="Escrita:"
                                disabled={isDisabled}
                              >
                                {NIVEL && NIVEL.map(i => (
                                  <Option key={i} value={i} title={i}>
                                    {i}
                                  </Option>
                                ))
                                }
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, 'conversacao']}
                              fieldKey={[field.fieldKey, `conversacao${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                placeholder="Conversação:"
                                disabled={isDisabled}
                              >
                                {NIVEL && NIVEL.map(i => (
                                  <Option key={i} value={i} title={i}>
                                    {i}
                                  </Option>
                                ))
                                }
                              </Select>

                            </Form.Item>


                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 20
                              }}
                            >
                              <MinusCircleOutlined
                                disabled={isDisabled}
                                onClick={() => {
                                  remove(field.name);
                                }}
                                style={{ marginRight: 5 }}
                              />
                                Remover
                            </div>
                          </div>
                        ))}

                        <Form.Item
                          className='custom-margin-form-item'
                        >
                          <Button
                            disabled={isDisabled}
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> Adicionar
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>

              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">CONHECIMENTOS DIVERSOS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
                autoComplete="off"
              >
                <Form.List name="conhecimentos_diversos">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map(field => (
                          <div key={field.key}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'descricao']}
                              fieldKey={[field.fieldKey, `descricao${field.key}`]}
                              rules={[{ required: true, message: 'Campo Obrigatório' }]}
                              className='custom-margin-form-item'
                              style={{ marginTop: 12 }}
                            >
                              <Select
                                className="size_select"
                                placeholder="Informática"
                                disabled={isDisabled}
                              >
                                {CONHECIMENTOS_DIVERSOS_INFORMATICA && CONHECIMENTOS_DIVERSOS_INFORMATICA.map(i => (
                                  <Option key={i} value={i} title={i}>
                                    {i}
                                  </Option>
                                ))
                                }
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'nivel']}
                              fieldKey={[field.fieldKey, `nivel${field.key}`]}
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                              className="custom-margin-form-item"
                            >
                              <Select
                                className="size_select"
                                placeholder="Nível"
                                disabled={isDisabled}
                              >
                                <Option value={'Básico'} title={'Básico'}>
                                  Básico
                                </Option>
                                <Option value={'Médio'} title={'Médio'}>
                                  Médio
                                </Option>
                                <Option value={'Intermediário'} title={'Intermediário'}>
                                  Intermediário
                                </Option>
                                <Option value={'Avançado'} title={'Avançado'}>
                                  Avançado
                                </Option>

                              </Select>
                            </Form.Item>


                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 20
                              }}
                            >
                              <MinusCircleOutlined
                                disabled={isDisabled}
                                onClick={() => {
                                  remove(field.name);
                                }}
                                style={{ marginRight: 5 }}
                              />
                                Remover
                            </div>
                          </div>
                        ))}

                        <Form.Item
                          className='custom-margin-form-item'
                        >
                          <Button
                            disabled={isDisabled}
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> Adicionar
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Form>
            </div>
          </div>
        </div>
      </div>


      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">EXPERIÊNCIA PROFISSIONAL</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-form-item"
                  name="experiencia"
                >
                  <label htmlFor="experiencia"><b>Primeiro emprego?</b></label> <br />
                  <Radio.Group
                    value={primeiroEmprego}
                    onChange={value => { setPrimeiroEmprego(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {primeiroEmprego === false &&
                  <>
                    <Form.Item
                      className="custom-margin-form-item"
                      name='nome_empresa'
                      rules={[
                        {
                          required: true,
                          message: 'Este campo é obrigatório',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nome da empresa:"
                        style={{ textTransform: 'lowercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <>
                      <Label title="Data de admissão" />
                      <Form.Item
                        className="custom-margin-form-item"
                        name='data_admissao'
                      >
                        <DatePicker
                          className="calendar-ant"
                          locale={locale}
                          onChange={(value) => {
                            setDataAdmissao(value?.format('YYYY-MM-DD'))
                          }}
                          format="DD/MM/YYYY"
                          size="large"
                          placeholder=""
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </>

                    <>
                      <Label title="Data de demissão" />
                      <Form.Item
                        className="custom-margin-form-item"
                        name='data_demissao'
                      >
                        <DatePicker
                          className="calendar-ant"
                          locale={locale}
                          onChange={(value) => {
                            setDataDemissao(value?.format('YYYY-MM-DD'))
                          }}
                          format="DD/MM/YYYY"
                          size="large"
                          placeholder=""
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </>

                    <Form.Item
                      className="custom-margin-form-item"
                      name='cargo_funcao'
                    >
                      <Input
                        placeholder="Cargo/função:"
                        style={{ textTransform: 'lowercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name='ultimo_salario'
                    >
                      <Input
                        size="large"
                        type="number"
                        min="1"
                        step="any"
                        onChange={(value) => {
                          console.log('changed', value);
                        }}
                        placeholder="Último salário:"
                        style={{ fontSize: 14 }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="desc_atividade"
                    >
                      <TextArea
                        placeholder="Descrição das Atividades:"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ textTransform: 'uppercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="motivo_desligamento"
                    >
                      <TextArea
                        placeholder="Motivo do desligamento:"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ textTransform: 'uppercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                  </>
                }

                {primeiroEmprego != null &&
                  <>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="pontos_fortes"
                    >
                      <TextArea
                        placeholder="Seus pontos fortes:"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ textTransform: 'uppercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="pontos_melhorias"
                    >
                      <TextArea
                        placeholder="Seus pontos de melhorias:"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ textTransform: 'uppercase' }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="disp_horario"
                      style={{ marginTop: 15 }}
                    >
                      <Checkbox.Group
                        onChange={onChangeDisponibilidade}
                        disabled={isDisabled}
                      >
                        <label><b>Disponibilidade de horário</b></label>
                        <Row>
                          <Col span={8}>
                            <Checkbox value="Comercial" style={{ lineHeight: '32px' }}>
                              Comercial
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="Manhã" style={{ lineHeight: '32px' }}>
                              Manhã
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="Tarde" style={{ lineHeight: '32px' }}>
                              Tarde
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="Noite" style={{ lineHeight: '32px' }}>
                              Noite
                            </Checkbox>
                          </Col>
                          <Col span={10}>
                            <Checkbox value="Fim de semana" style={{ lineHeight: '32px' }}>
                              Fim de semana
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </>
                }

              </Form>
            </div>
          </div>
        </div>

      </div>

      {/* SESSÃO 3 */}
      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">EVENTOS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-form-item"
                  name="vagas_eventos"
                >
                  <label htmlFor="vagas_eventos"><b>Deseja participar também de vagas para eventos?</b></label> <br />
                  <Radio.Group
                    value={participarVagas}
                    onChange={value => { setParticiparVagas(value.target.value) }}
                    disabled={isDisabled}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {participarVagas === true &&
                  <>
                    <Label title="Altura" />
                    <Form.Item
                      className="custom-margin-form-item"
                      name="altura"
                    >
                      <Row>
                        <Col span={24}>
                          <Slider
                            min={1.3}
                            max={2}
                            value={altura}
                            defaultValue={1.5}
                            onChange={e => {
                              console.log('ALTURA ', e)
                              setAltura(e)
                            }}
                            step={0.01}
                            disabled={isDisabled}
                          />
                        </Col>

                      </Row>
                    </Form.Item>

                    <Label title="Peso" />
                    <Form.Item
                      className="custom-margin-form-item"
                      name="peso"
                    >
                      <Slider
                        min={30}
                        max={150}
                        defaultValue={50}
                        onChange={e => {
                          console.log('PESO ', e)
                          setPeso(e)
                        }}
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="num_sapato"
                    >
                      <Input
                        type="number"
                        step="any"
                        max="60"
                        placeholder="Número do sapato:"
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="manequim_calca"
                    >
                      <Input
                        type="number"
                        step="any"
                        max="70"
                        placeholder="Manequim calça:"
                        disabled={isDisabled}
                      />
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="manequim_blusa"
                    >
                      <Select
                        className="size_select"
                        placeholder="Manequim blusa:"
                        disabled={isDisabled}
                      >
                        {MANEQUIM_BLUSA && MANEQUIM_BLUSA.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="cor_olhos"
                    >
                      <Select
                        className="size_select"
                        placeholder="Cor dos olhos:"
                        disabled={isDisabled}
                      >
                        {COR_OLHOS && COR_OLHOS.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="tipo_cabelo"
                    >
                      <Select
                        className="size_select"
                        placeholder="Tipo de cabelo:"
                        disabled={isDisabled}
                      >
                        {TIPO_CABELO && TIPO_CABELO.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="tom_cabelo_atual"
                    >
                      <Select
                        className="size_select"
                        placeholder="Tom de cabelo atual:"
                        disabled={isDisabled}
                      >
                        {TOM_CABELO && TOM_CABELO.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="tom_cabelo_natural"
                    >
                      <Select
                        className="size_select"
                        placeholder="Tom de cabelo natural:"
                        disabled={isDisabled}
                      >
                        {TOM_CABELO && TOM_CABELO.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="tom_pele"
                    >
                      <Select
                        className="size_select"
                        placeholder="Tom da pele:"
                        disabled={isDisabled}
                      >
                        {TOM_PELE && TOM_PELE.map(i => (
                          <Option key={i} value={i} title={i}>
                            {i}
                          </Option>
                        ))
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="custom-margin-form-item"
                      name="caracteristicas"
                      style={{ marginTop: 15 }}
                    >
                      <Checkbox.Group
                        // onChange={onChangePNE}
                        disabled={isDisabled}
                      >
                        <Row>
                          <Col span={12}>
                            <Checkbox value="oculos">Uso óculos</Checkbox>
                          </Col>
                          <Col span={12}>
                            <Checkbox value="aparelho">Uso aparelho</Checkbox>
                          </Col>
                          <Col span={12}>
                            <Checkbox value="piercing">Tenho piercing</Checkbox>
                          </Col>
                          <Col span={12}>
                            <Checkbox value="tatuagem">Tenho tatuagem</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>

                  </>
                }

              </Form>
            </div>
          </div>
        </div>

      </div>

      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">DADOS BANCÁRIOS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="pessoa_fisica"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="tipo_conta"
                >
                  <Select placeholder="Tipo de conta"
                    // onChange={handleChangeTipoBanco}
                    disabled={isDisabled}
                    className="size_select"
                  >
                    <Option value="C">
                      Corrente
                    </Option>
                    <Option value="P">
                      Poupança
                    </Option>

                  </Select>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="cd_banco"
                >
                  <Select
                    // options={codigosBanco}
                    showSearch
                    value={cdBanco}
                    placeholder="Código do banco:"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    onChange={(value) => {
                      if (value?.length === 3) {
                        const banco = CODIGOS_BANCOS && CODIGOS_BANCOS.find(cod => cod.label === value)

                        form.setFieldsValue({
                          nome_banco: banco.value
                        })
                      } else {
                        console.log('banco não encontrado')
                      }
                    }}
                  >
                    {CODIGOS_BANCOS && CODIGOS_BANCOS.map(cod => (
                      <Option key={cod.label} value={cod.label}> {cod.label} </Option>
                    ))
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="nome_banco"
                >
                  <Input
                    placeholder="Nome do Banco:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="operacao"
                >
                  <MaskedInput
                    mask="111"
                    placeholder="Operação:"
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="favorecido"
                >
                  <Input
                    placeholder="Favorecido:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="agencia"
                >
                  <Input
                    placeholder="Agência:"
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="digito_agencia"
                >
                  <Input
                    placeholder="Dígito agência:"
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="conta"
                >
                  <Input
                    placeholder="Conta:"
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="digito_conta"
                >
                  <Input
                    placeholder="Dígito conta:"
                    disabled={isDisabled}
                  />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </div>

      { location && location.state !== 'VIEW' && (
        <Form
          {...formItemLayout}
          form={form}
          name="pessoa_fisica"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
            <div className="container">
              <div className="row" >
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Form.Item
                    className="custom-form-item"
                  >

                    <Spin spinning={isLoading}>
                      <Button
                        type="primary" htmlType="submit" id="btn_cadastrar_fornecedor" className="ant-input"
                      >

                        {id ? 'ATUALIZAR' : 'CADASTRAR'}
                      </Button>
                    </Spin>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}


    </>
  );
}
