
import React from 'react';

const Label = ({ title, name, style }) => {
  return (
    <div className="custom-form-item" style={style}>
      <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
        <label htmlFor={name ? name : title} style={{ marginBottom: 0 }}><b> {title} </b></label>
      </div>
    </div>
  );
}

export default Label;


