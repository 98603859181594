import React from 'react';
import { Layout } from 'antd';
import { darkblue, blue } from '../../styles/colors';
import SocialFooter from '../SocialFooter';

const { Footer: FooterAnt } = Layout;

export default function Footer() {
  return (
    <>
      <FooterAnt style={{ textAlign: 'center', backgroundColor: blue }}>
        <section className="section-footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-3">
                <div className="widget">
                  <h3 className="text-justify" style={{ fontSize: 16, color: '#FFF' }}>A ELETTA</h3>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>
                    A Eletta é uma agência de Live Marketing que assessora e executa eventos sociais, governamentais e corporativos.
					</p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-2">
                <div className="widget">
                  <h3 className="text-justify" style={{ fontSize: 16, color: '#FFF' }}>EVENTOS</h3>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>
                    Planejamos, organizamos e realizamos eventos como: Casamentos, Formaturas, Eventos Governamentais, Festas Infantis, Bailes de Debutantes e Eventos Corporativos
					</p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-2">
                <div className="widget">
                  <h3 className="text-justify" style={{ fontSize: 16, color: '#FFF' }}>COMO FAZEMOS</h3>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>
                    Oferecemos soluções inovadoras e equipe especializada, o que garante a tranquilidade de nossos clientes e a satisfação em todo o processo.
					</p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-2">
                <div className="widget">
                  <h3 className="text-justify" style={{ fontSize: 16, color: '#FFF' }}>EBOOKS</h3>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>
                    Elaboramos materiais que possam auxiliar nossos clientes com dicas e sugestões, orientando quais as melhores formas de realizar seu evento.
					        </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3">
                <div className="widget  ">
                  <h3 className="text-justify" style={{ fontSize: 16, color: '#FFF' }}>CONTATO</h3>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>SBS QD 2 Bloco E Sala 206 Sobreloja Brasília-DF</p>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}> CEP: 70070-120</p>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>Tel.: +55 (61) 4101-1991</p>
                  <p className="text-justify" style={{ fontSize: 10, color: '#FFF' }}>E-mail: eventos@eletta.com.br</p>
                </div>
              </div>

            </div>

            <div className="row">
              <SocialFooter />
            </div>
          </div>
        </section>
      </FooterAnt>
      <footer className="page-footer font-small" style={{ backgroundColor: darkblue }}>
        <div className="footer-copyright text-center py-3">
          <span style={{ color: '#FFF' }}>© Todos os direitos reservados. Eletta Produções e Eventos Ltda.</span>
        </div>
      </footer>
    </>
  );
}
