import React from 'react';
import PropTypes from 'prop-types';

// import { Wrapper } from './styles';
import { primary } from '../../../styles/colors';

export default function AuthLayout({ children }) {
  return (
    <div
      style={{
        height: '100%',
        background: primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
    {/* // <Wrapper> */}

      {children}
      {/* </Wrapper> */}
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
