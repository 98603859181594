import React from 'react';
import { Layout } from 'antd';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const { Content } = Layout;

export default function DefaultLayout({ children }) {
  return (
    <>
      <Header />
      <Content className="site-layout"
        // style={{ padding: '0 50px', marginTop: 64, minHeight: '100%' }}
      >
        <div className="site-layout-background"
          style={{ paddingTop: 24, paddingBottom: 24 }}
        >
          {children}
        </div>
      </Content>

      <Footer />
    </>
  );
}
