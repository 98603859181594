import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Button, } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { signOutRequest } from '../../redux/modules/auth/actions';
import api from '../../services/api';

export default function Header() {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(state => state.user.user)
  const token = useSelector(state => state.auth.token)
  const auth = useSelector(state => state.auth.signed)

  const toggle = () => setIsOpen(!isOpen);

  if(user)
    api.defaults.headers.Authorization = `Bearer ${token}`;

  function logout() {
    dispatch(signOutRequest())
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <a href="/dashboard/admin">
          DASHBOARD
        </a>
      </Menu.Item>
      <Menu.Item>
        <span onClick={logout}>
          SAIR
        </span>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout style={{ backgroundColor: '#FFF' }} >
      <div className="py-3" style={{ backgroundColor: '#FFF', borderBottom: '1px solid #EEE' }}>
        <Navbar className="container" color="light" light expand="md">
          {/* <NavbarBrand href="/">reactstrap</NavbarBrand> */}
          <img src="https://www.eletta.com.br/images/front/logo.png" style={{ maxWidth: 173 }} alt="Eletta - Agência de Eventos" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br" style={{ color: '#8e8e8e', fontSize: 13 }}>INÍCIO</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/a-eletta" style={{ color: '#8e8e8e', fontSize: 13 }}>A ELETTA</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/eventos" style={{ color: '#8e8e8e', fontSize: 13 }}>EVENTOS</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/assessoria" style={{ color: '#8e8e8e', fontSize: 13 }}>ASSESSORIA</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/materiais-ebooks" style={{ color: '#8e8e8e', fontSize: 13 }}>MATERIAIS</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/blog" style={{ color: '#8e8e8e', fontSize: 13 }}>BLOG</NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink href="https://www.eletta.com.br/contato" style={{ color: '#8e8e8e', fontSize: 13 }}>ORÇAMENTO</NavLink>
              </NavItem>
              <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <a href="/trabalhe-conosco/pessoa-juridica" style={{fontSize: 13}}>
                          PESSOA JURÍDICA
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/trabalhe-conosco/pessoa-fisica" style={{fontSize: 13}}>
                          PESSOA FÍSICA
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                //  onVisibleChange={this.handleVisibleChange}
                >
                  <Button style={{ border: 'none', display: 'flex', alignItems: 'center', boxShadow: 'none', color: 'rgb(142, 142, 142)', paddingBottom: 0, fontSize: 13 }}>
                    TRABALHE CONOSCO <DownOutlined />
                  </Button>
                </Dropdown>
              {user && auth &&
                <Dropdown
                  overlay={menu}
                //  onVisibleChange={this.handleVisibleChange}
                >
                  <Button style={{ border: 'none', display: 'flex', alignItems: 'center', boxShadow: 'none', color: 'rgb(142, 142, 142)', paddingBottom: 0, fontSize: 13 }}>
                    {user && user.nome.toUpperCase()} <DownOutlined />
                  </Button>
                </Dropdown>
              }
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </Layout>
  )
}
