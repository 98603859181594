import React from 'react';
import { Route, Redirect } from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'
import AuthLayout from '../pages/_layouts/auth'
import { store } from '../redux';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isHeader,
  ...rest
}) {
  const { signed } = store.getState().auth
  // const signed = false

  if (!signed && isPrivate) {
    return <Redirect to="/login" />
  }

  // if (signed && !isPrivate) {
  //   return <Redirect to="/fornecedores" />
  // }

  if (signed && rest.path === '/login') {
    return <Redirect to="/trabalhe-conosco/pessoa-juridica" />
  }

  // const Layout = signed ? DefaultLayout : AuthLayout;
  const Layout = !isHeader ? AuthLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
