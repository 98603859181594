export const primary = '#354364'

export const black = '#000000'
export const dark = '#444444'

export const darkgray = '#707070'
// export const darkgray = '#ADADAD'
export const lightgray = '#CCCCCC'

export const blue = '#354364'
export const darkblue = '#29334D'
export const lightgreen = '#569A98'


// SUCCESS / ERROR
export const green = '#25802C'
export const red = '#DE3B3B'
