import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route'

import SignIn from '../pages/SignIn'

import Providers from '../pages/Providers';
import PessoaFisica from '../pages/PessoaFisica';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact isHeader component={Home} />
      <Route path="/login" component={SignIn} />
      <Route path="/trabalhe-conosco/pessoa-juridica/:id?" isHeader component={Providers} />
      <Route path="/trabalhe-conosco/pessoa-fisica/:id?" isHeader component={PessoaFisica} />
      {/* <Route path="/cadastro/fornecedores" isHeader component={Providers} /> */}
      <Route path="/dashboard/admin" isPrivate isHeader component={Dashboard} />

      <Route path="*" isHeader component={Home} />
    </Switch>
  );
}
