import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { primary } from '../../styles/colors';

import { useDispatch } from 'react-redux';

import { signInRequest } from "../../redux/modules/auth/actions";

export default function SignIn() {
  const dispatch = useDispatch()

  const handleSubmit = values => {
    dispatch(signInRequest(values.email, values.password))
  };

  // const onFinishFailed = errorInfo => {
  //   console.log('Failed:', errorInfo);
  // };

  return (
    <Card style={{minWidth: 300}}>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
        <img src="https://www.eletta.com.br/images/front/logo.png" style={{ maxWidth: 173 }} alt="Eletta - Agência de Eventos" />
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'E-mail é obrigatório',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined
              className="site-form-item-icon"
              />}
            placeholder="E-mail" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Senha é obrigatório',
            },
          ]}
        >
          <Input.Password
            prefix={ <LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" id="btn-login" className="login-form-button" style={{ backgroundColor: primary, border: 0 }}>
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
