import { takeLatest, call, put, all } from 'redux-saga/effects';
import { notification } from 'antd';

import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signInFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, 'login', {
      email,
      password,
    });

    const { token, user } = response.data;

    yield put(signInSuccess(token, user));

    history.push('/dashboard/admin');
  } catch (err) {
    notification.open({
      message: 'Falha na autenticação',
      description:
        'por favor, verifique seus dados.',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
    yield put(signInFailure());
  }
}

export function signOut() {
  // history.go()
  localStorage.clear()
  setTimeout(() => {
    history.push('/login');
  }, 500);
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
