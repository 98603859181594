import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaPinterestP, FaLinkedinIn } from 'react-icons/fa'

import { primary } from '../../styles/colors';

export default function SocialFooter() {
  return (
    <div className="col-sm-12">
      <div className="social_footer">
        <div className="icon">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link-facebook">
            <i className="fa fa-facebook"></i>
            <FaFacebookF color={primary} size={16} />
          </a>
        </div>
        <div className="icon">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-link-instagram">
            <i className="fa fa-instagram"></i>
            <FaInstagram color={primary} size={16} />
          </a>
        </div>
        <div className="icon">
          <a href="https://br.pinterest.com/sejaeletta/" target="_blank" rel="noopener noreferrer" className="social-link-twitter">
            <i className="fa fa-pinterest-p" aria-hidden="true"></i>
            <FaPinterestP color={primary} size={16} />
          </a>
        </div>
        <div className="icon">
          <a href="https://youtube.com.br" target="_blank" rel="noopener noreferrer" className="social-link-youtube">
            <i className="fa fa-youtube-play"></i>
            <FaYoutube color={primary} size={16} />
          </a>
        </div>
        <div className="icon">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link-linkedin">
            <i className="fa fa-linkedin"></i>
            <FaLinkedinIn color={primary} size={16} />
          </a>
        </div>
      </div>
    </div>
  );
}
