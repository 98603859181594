import React, { useState, useEffect } from 'react';
import { FaEdit, FaEye } from 'react-icons/fa'

import { Table, Tag, PageHeader, Card, Button, Modal } from 'antd';
// import reqwest from 'reqwest';

import api from '../../services/api';
import history from '../../services/history';
import { useDispatch } from 'react-redux';
import { signOutRequest } from '../../redux/modules/auth/actions';

export default function Dashboard() {
  const dispatch = useDispatch();

  const [data, setData] = useState([])
  const [fornecedores, setFornecedores] = useState([])
  const [clientes, setClientes] = useState([])
  const [parceiros, setParceiros] = useState([])
  const [rh, setRh] = useState([])

  useEffect(() => {
    getProviders()
  }, [])

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      // sorter: true,
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: email => (
        <>
          {
            email && email.toLowerCase()
          }
        </>
      ),
    },
    {
      title: 'Celular',
      dataIndex: 'celular',
      render: celular => (
        <>
          {
            celular && celular.replace(/(\d{2})(\d{5})(\d{4})/, function( regex, arg1, arg2, arg3) {
            return '('+arg1+') ' + arg2 + '-' + arg3
          })
          }
        </>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      // filters: [
      //   { text: 'Juridica', value: 'J' },
      //   { text: 'Física', value: 'F' },
      // ],
      width: '20%',
      render: tipo => (

        <Tag color={tipo === 'J' ? '#98D9E0' : '#222745'} >
          {tipo && tipo.toUpperCase()}
        </Tag>

      ),
      // render: tipo => `${tipo === 'J' ? 'Juridica' : 'Física'}`,
    },
    {
      title: '#',
      render: provider => (
        <>
          <FaEdit onClick={() => handleAction(provider, 'EDIT')} style={{ cursor: 'pointer' }} />
          <FaEye onClick={() => handleAction(provider, 'VIEW')} style={{ marginLeft: 7, cursor: 'pointer', fontSize: 15 }} />
        </>
      )
    }
  ];

  function handleAction(provider, action) {
    let path

    provider.tipo === "J" ? path = 'pessoa-juridica' : path = 'pessoa-fisica'
    console.log('PATH ', path)

    history.push(`/trabalhe-conosco/${path}/${provider.id}`, action)
  }

  function logout() {
    dispatch(signOutRequest())
  }

  async function getProviders() {
    try {
      const response = await api.get('/api/fornecedores')

      if (response.status === 200) {
        console.log(response.data)

        const fornecedores = response.data.filter(f => f.categoria_id === 1)
        setFornecedores(fornecedores)

        const clientes = response.data.filter(f => f.categoria_id === 2)
        setClientes(clientes)

        const parceiros = response.data.filter(f => f.categoria_id === 3)
        setParceiros(parceiros)

        const _rh = response.data.filter(f => f.categoria_id === 4)
        setRh(_rh)

        setData(response.data)
      }

    } catch (error) {
      if(error.response?.status === 401){
        return Modal.error({
          title: 'Sessão expirada!',
          content: 'Por favor, se autentique novamente.',
          onOk: () => {
            logout()
          },
        })
      }
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <PageHeader
              className="site-page-header"
              title="DASHBOARD"
              // subTitle="Fornecedores"
              style={{ paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}
            />
            <Button id="btn-add-dashboard" onClick={() => { history.push('/trabalhe-conosco/pessoa-juridica') }}>
              Adicionar
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" style={{ marginTop: 10 }}>
          <Card title="Fornecedores" bordered={false} style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)', borderRadius: 4 }}>
            <p style={{ textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              {fornecedores && fornecedores.length}
            </p>
          </Card>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" style={{ marginTop: 10 }}>
          <Card title="Clientes" bordered={false} style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)', borderRadius: 4 }}>
            <p style={{ textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              {clientes && clientes.length}
            </p>
          </Card>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" style={{ marginTop: 10 }}>
          <Card title="Parceiros" bordered={false} style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)', borderRadius: 4 }}>
            <p style={{ textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              {parceiros && parceiros.length}
            </p>
          </Card>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" style={{ marginTop: 10 }}>
          <Card title="RH" bordered={false} style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)', borderRadius: 4 }}>
            <p style={{ textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              {rh && rh.length}
            </p>
          </Card>
        </div>
      </div>

      <div style={{ marginTop: 35 }}>
        <Table
          columns={columns}
          rowKey={record => record.id}
          dataSource={data}
          emptyText="Sem fornecedores cadastrados"
        />
      </div>

    </div>
  )
}
