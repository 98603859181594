import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  PageHeader,
  notification,
  Modal,
  Radio
} from 'antd';
import { UploadOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import MaskedInput from 'antd-mask-input'
import { produce } from 'immer'

import api from '../../services/api'
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOutRequest } from '../../redux/modules/auth/actions';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export default function Providers({ history }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user)
  const auth = useSelector(state => state.auth.signed)
  // STATES
  const [cep, setCep] = useState('')
  const [logradouro, setLogradouro] = useState('')
  // const [numero, setNumero] = useState('')
  const [cidade, setCidade] = useState('')
  const [bairro, setBairro] = useState('')
  const [estado, setEstado] = useState('')

  const [cnpj, setCNPJ] = useState('')

  const [categorias, setCategorias] = useState([])
  const [categoria_id, setCategoriaId] = useState()

  const [atividades, setAtividades] = useState([])
  const [atividade_id, setAtividadeId] = useState()

  const [portfolio_id, setPortfolioId] = useState()
  const [logo_id, setLogoId] = useState()

  const [, setPortfolioIdUpdate] = useState()
  const [logo_id_update, setLogoIdUpdate] = useState()

  const [isDisabled, setIsDisabled] = useState(false)

  const [, setTipoConta] = useState()
  const [tipo_pessoa, setTipoPessoa] = useState()
  const [tipo_empresa, setTipoEmpresa] = useState()


  const [tipos_empresa, setTiposEmpresa] = useState()

  const [, setSimplesNacional] = useState()

  const [contatos, setContatos] = useState([
    {
      nomeFuncionario: '',
      cargo: '',
      celular: null,
      email: ''
    }
  ])

  const [btnDisabled, setBtnDisabled] = useState(false)

  const { id } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    if (history.location.state === 'VIEW') {
      setIsDisabled(true)
    }

    getCategorias()
    getAtividades()
    getTipoEmpresa()

    if (id) {
      getPessoa()
    }
  }, [id])

  // FUNCTIONS

  function logout() {
    dispatch(signOutRequest())
  }

  async function getLogo(id) {
    const response = await api.get(`/api/logo/${id}`)

    if (response.status === 200) {
      document.getElementsByClassName("ant-upload-drag-container")[0].innerHTML = `<img id="preview_logo" name="prvw" style="width: 150px; max-width: 150px"; />`
      document.getElementById("preview_logo").setAttribute('src', response.data.path)
    }
  }

  async function getCategorias() {
    try {
      const response = await api.get('/api/categorias')

      if (response.status === 200) {
        setCategorias(response.data)
      }
    } catch (err) {
      if (user && err.response?.status === 401) {
        Modal.error({
          title: 'Sessão expirada!',
          content: 'Por favor, se autentique novamente.',
          onOk: () => {
            logout()
          },
        })
      }
    }
  }

  async function getAtividades() {
    const response = await api.get('/api/atividades')

    if (response.status === 200) {
      setAtividades(response.data)
    }
  }

  async function getTipoEmpresa() {
    const response = await api.get('/api/tipo_empresa')

    if (response.status === 200) {
      // console.log(response.data)
      setTiposEmpresa(response.data)
    }
  }

  async function getPessoa() {
    // const response = await api.get('/api/fornecedores')
    const response = await api.get(`/api/pessoa/${id}`)


    if (response.status === 200) {
      const pessoa = response.data.pessoa
      const pessoa_juridica = response.data.pessoa_juridica
      const contatos = response.data.contatos
      const endereco = response.data.endereco
      const dados_bancarios = response.data.dados_bancarios
      // console.log(response.data)

      if (response.data.pessoa.logo_id) {
        await getLogo(response.data.pessoa.logo_id)
        setLogoIdUpdate(response.data.pessoa.logo_id)
      }

      setContatos([contatos])

      // contatos.map(c => {
      //   form.setFieldsValue({
      //     cargo_0: c.cargo
      //   })
      // })

      setTipoPessoa(response.data.pessoa_juridica?.tipo_pessoa)
      setTipoEmpresa(response.data.pessoa_juridica?.tipo_empresa_id)

      console.log('DADOS ', response.data)
      form.setFieldsValue({
        nome: pessoa?.nome,
        email: pessoa?.email,
        telefone: pessoa?.telefone,
        celular: pessoa?.celular,
        site: pessoa?.site,

        cep: endereco?.cep,
        logradouro: endereco?.logradouro,
        numero: String(endereco?.numero),
        complemento: endereco?.complemento,
        cidade: endereco?.cidade,
        bairro: endereco?.bairro,
        estado: endereco?.estado,

        cnpj: pessoa_juridica?.cnpj,
        razao_social: pessoa_juridica?.razao_social,
        insc_municipal: pessoa_juridica?.insc_municipal,
        insc_estadual: pessoa_juridica?.insc_estadual,
        opta_simples_nacional: pessoa_juridica?.opta_simples_nacional,
        tipo_empresa_id: pessoa_juridica?.tipo_empresa_id,

        cnpj_titular: dados_bancarios?.cnpj_titular,
        cpf_titular: dados_bancarios?.cpf_titular,
        tipo_conta: dados_bancarios?.tipo_conta,
        tipo_pessoa: dados_bancarios?.tipo_pessoa,
        cd_banco: String(dados_bancarios?.cd_banco),
        nome_banco: dados_bancarios?.nome_banco,
        operacao: String(dados_bancarios?.operacao),
        favorecido: dados_bancarios?.favorecido,
        agencia: dados_bancarios?.agencia,
        digito_agencia: dados_bancarios?.digito_agencia,
        conta: dados_bancarios?.conta,
        digito_conta: dados_bancarios?.digito_conta,

        atividade: pessoa.atividade_id,
        categoria: pessoa.categoria_id,

        facebook: pessoa.rede_social.facebook,
        twitter: pessoa.rede_social.twitter,
        instagram: pessoa.rede_social.instagram,
        linkedin: pessoa.rede_social.linkedin,
        youtube: pessoa.rede_social.youtube,
      })
    }
  }


  async function onFinish(values) {
    setBtnDisabled(true)
    values.atividade_id = atividade_id
    values.categoria_id = id ? categoria_id : 1
    values.tipo = 'J'
    values.contatos = contatos
    values.logo_id = logo_id
    values.portfolio_id = portfolio_id
    values.telefone = Number(values.telefone?.replace(/\D/g, ''))
    values.celular = Number(values.celular?.replace(/\D/g, ''))
    values.cep = Number(values.cep?.replace(/\D/g, ''))
    values.numero = Number(values.numero?.replace(/\D/g, ''))
    values.cd_banco = Number(values.cd_banco?.replace(/\D/g, ''))
    values.operacao = Number(values.operacao?.replace(/\D/g, ''))
    // values

    console.log('Received values of form: ', values);

    try {

      let response
      id
        ? response = await api.put(`/api/atualizar/pessoa_juridica/${id}`, values)
        : response = await api.post('/api/cadastro/pessoa_juridica', values)

      if (response.status === 201) {
        setBtnDisabled(false)
        Modal.success({
          title: 'Registro salvo com sucesso!',
          onOk: () => {
            setTimeout(() => {
              document.getElementById("register_nome").focus()

              history.go()
            }, 300);
          },
        })
      } else if (response.status === 200) {
        setBtnDisabled(false)
        Modal.success({
          title: 'Registro atualizado com sucesso!',
          onOk: () => history.push('/dashboard/admin')
        })
      } else {
        setBtnDisabled(false)
        Modal.error({
          title: 'Não foi possível concluir seu cadastro, tente novamente mais tarde.'
        })
      }
    } catch (error) {
      setBtnDisabled(false)
      return Modal.error({
        title: 'Não foi possível concluir seu cadastro, tente novamente mais tarde.'
      })
    }
  };

  async function handleSubmitFile(file) {
    // console.log(file)
    const { name: nome, size } = file.file
    const path = new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        const b64 = reader.result;
        resolve(b64)
      };
    });

    path.then(async p => {
      const objFile = {
        nome,
        path: p,
        tamanho: size
      }
      // console.log(objFile)

      // const response = await api.post('/api/upload/arquivo', objFile)
      let response
      id
        ? response = await api.put(`/api/upload/arquivo/${logo_id_update}`, objFile)
        : response = await api.post('/api/upload/arquivo', objFile)

      // console.log(response.data.id)

      if (!id) {
        file.filename === 'logotipo'
          ? setLogoId(response.data.id)
          : setPortfolioId(response.data.id)
      }

    })
  }

  // ON CHANGE INPUT //

  function handleChangeCategoria(value) {
    setCategoriaId(value)
    // console.log(`selected ${value}`);
  }

  function handleChangeTipoEmpresa(value) {
    setTipoEmpresa(value)
  }

  function onChangeOptaSimplesNacional(value) {
    setSimplesNacional(value)
  }

  function handleChangeTipoBanco(value) {
    setTipoConta(value)
    // console.log(`selected ${value}`);
  }

  function handleChangeCPFCNPJ(value) {
    setTipoPessoa(value)
    if (value === 'CNPJ') {
      form.setFieldsValue({
        cnpj_banco: cnpj
      })
    }
  }

  function handleChangeAtividade(value) {
    setAtividadeId(value)
    // console.log(`selected ${value}`);
  }

  function onChangeContato(value, index, objeto) {

    if (objeto === 'nomeFuncionario') {
      setContatos(currentContato =>
        produce(currentContato, v => {
          v[index].nomeFuncionario = value;
        })
      )
    }
    else if (objeto === 'cargo') {
      setContatos(currentContato =>
        produce(currentContato, v => {
          v[index].cargo = value;
        })
      )
    }
    else if (objeto === 'celular') {
      setContatos(currentContato =>
        produce(currentContato, v => {
          v[index].celular = Number(value?.replace(/\D/g, ''));
        })
      )
    }
    else if (objeto === 'email') {
      setContatos(currentContato =>
        produce(currentContato, v => {
          v[index].email = value;
        })
      )
    }

  }

  function onChangeCep(value) {
    const cepRegex = value?.replace(/\D/g, '')
    setCep(cepRegex)
  }

  function onBlurCNPJ(value) {
    setCNPJ(value)
    console.log(`CHANGE CNPJ `, value)
  }

  async function verificaCNPJ(value) {
    try {
      const response = await api.post('api/verifica_cnpj', {
        cnpj: value
      })
      console.log(response)
    } catch (err) {
      if (err.response.status === 400) {
        form.setFieldsValue({
          cnpj: ''
        })

        Modal.error({
          title: 'CNPJ já cadastrado.'
        })
      }
    }


  }

  // --- ON CHANGE INPUT --- //

  function adicionarContato() {
    const novoContato = {
      nomeFuncionario: '',
      cargo: '',
      celular: null,
      email: ''
    }
    setContatos([...contatos, novoContato])
  }

  const normFile = e => {
    const file = e.file.originFileObj
    // console.log('FILE - ', file)
    // const fileReader = new FileReader()
    if (file.size <= 4000000) {
      if (Array.isArray(e)) {
        return e;
      }
    } else {
      notification.open({
        message: 'Tamanho inválido',
        description:
          'Não foi possível anexar seu arquivo, ela não se encaixa no tamanho especificado!',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });

      return
    }

    return e && e.fileList;
  };

  // filereader
  const handleLogotipo = e => {
    const file = e.file.originFileObj
    // console.log('FILE - ', file)
    if (file.size <= 5000000) {
      const fileReader = new FileReader()

      fileReader.onloadend = function () {
        // document.getElementsByClassName("ant-upload-drag-container")[0].style.display = "none"
        document.getElementsByClassName("ant-upload-drag-container")[0].innerHTML = `<img id="preview_logo" name="prvw" style="width: 150px; max-width: 150px"; />`
        document.getElementById("preview_logo").setAttribute('src', fileReader.result)
      }
      fileReader.readAsDataURL(file)

    } else {
      notification.open({
        message: 'Tamanho inválido',
        description:
          'Não foi possível anexar sua imagem, ela não se encaixa no tamanho especificado!',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
      document.getElementById("preview_logo") && document.getElementById("preview_logo").setAttribute('src', '')
    }
  }

  async function getFromCEP() {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    if (response.status === 200) {
      form.setFieldsValue({
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
        logradouro: response.data.logradouro,

      })
      setLogradouro(response.data.logradouro)
      setBairro(response.data.bairro)
      setCidade(response.data.localidade)
      setEstado(response.data.uf)
    }
  }

  return (
    <>
      {/* <div className="px-3" style={{ backgroundColor: '#FFF' }}> */}
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <PageHeader
              className="site-page-header"
              title="TRABALHE CONOSCO"
              subTitle="Pessoa Jurídica"
              style={{ paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="px-3" style={{ marginTop: 50, backgroundColor: '#FFF' }}>
        {/* <div className="col-md-10 col-md-offset-1"> */}
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">DADOS BÁSICOS</h2>
            </div>
            <div className="col-md-6">

              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="nome"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Nome fantasia:" title="Por favor, nome fantasia." disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Digite um e-mail válido!',
                    },
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="E-mail:" disabled={isDisabled} style={{ textTransform: 'lowercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="telefone"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput mask="(11) 1111-1111" placeholder="Telefone:" minLength={11} disabled={isDisabled} />
                  {/* <Input placeholder="Telefone:" /> */}
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    }
                  ]}
                >
                  <MaskedInput mask="(11) 11111-1111" placeholder="Celular:" disabled={isDisabled} />
                  {/* <Input placeholder="Celular:" /> */}
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="site"
                >
                  <Input placeholder="Site:" disabled={isDisabled} style={{ textTransform: 'lowercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="atividade"
                  rules={[
                    {
                      required: true,
                      message: 'Insira uma atividade!',
                    },
                  ]}
                >
                  <Select
                    initialvalues="teste"
                    placeholder="Atividade"
                    onChange={handleChangeAtividade}
                    disabled={isDisabled}
                    className="size_select"
                  >
                    {atividades && atividades.map(ativ => (
                      <Option key={ativ.id} value={ativ.id} title={ativ?.nome}>
                        {ativ.nome}
                      </Option>
                    ))
                    }
                  </Select>
                </Form.Item>

                {(id || user) &&
                  <Form.Item
                    className="custom-form-item"
                    name="categoria"
                    rules={[
                      {
                        required: true,
                        message: 'Insira uma categoria!',
                      },
                    ]}
                  >
                    <Select initialvalues={1} placeholder="Categoria" onChange={handleChangeCategoria} disabled={isDisabled} className="size_select">
                      {categorias && categorias.map(cat => (
                        <Option key={cat.id} value={cat.id}>
                          {cat.nome && cat.nome.charAt(0).toUpperCase() + cat.nome.slice(1)}
                        </Option>
                      ))
                      }
                    </Select>
                  </Form.Item>
                }
              </Form>

            </div>
          </div>
        </div>

      </div>

      {/* 2 SESSÃO */}

      <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">ENDEREÇO</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="cep"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="11.111-111" placeholder="CEP:"
                    onBlur={getFromCEP} onChange={e => onChangeCep(e.target.value)}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="logradouro"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Logradouro:" value={logradouro} disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="numero"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="1111111111"
                    placeholder="Número:"
                    style={{ textTransform: 'uppercase' }}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="complemento"
                >
                  <Input placeholder="Complemento:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="cidade"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Cidade:" value={cidade} disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="bairro"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Bairro:" value={bairro} disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="estado"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Estado:" value={estado} disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>

      </div>

      {/* 3 SESSÃO */}

      <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">DADOS COMPLEMENTARES</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="cnpj"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="11.111.111/1111-11"
                    placeholder="CNPJ:"
                    disabled={isDisabled}
                    onChange={e => onBlurCNPJ(e.target?.value)}
                    onBlur={e => verificaCNPJ(e.target?.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="razao_social"
                  rules={[
                    {
                      required: true,
                      message: 'Este campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Razão social:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="insc_estadual"
                >
                  <Input placeholder="Inscrição estadual:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="insc_municipal"
                >
                  <Input placeholder="Inscrição municipal:" disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="tipo_empresa_id"
                >
                  <Select placeholder="Tipo de empresa" onChange={handleChangeTipoEmpresa} disabled={isDisabled} className="size_select">
                    {tipos_empresa && tipos_empresa.map(tipo => (
                      <Option key={tipo.id} value={tipo.id}>
                        {tipo?.nome}
                      </Option>
                    ))
                    }
                  </Select>
                </Form.Item>

                <Form.Item className="custom-margin-form-item" name="opta_simples_nacional">
                  <Radio.Group onChange={onChangeOptaSimplesNacional}>
                    <Radio value={true}>Optante pelo simples nacional</Radio>
                    <Radio value={false}>Não Optante pelo simples nacional</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="telefone_alt"
                >
                  <Input placeholder="Telefone alternativo:" maxLength={12}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="observacoes"
                >
                  <TextArea placeholder="Observações:" autoSize={{ minRows: 2, maxRows: 6 }} disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="horario_funcionamento"
                >
                  <Input placeholder="Horário de funcionamento:" disabled={isDisabled} />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                >
                  <Form.Item name="logotipo" valuePropName="fileList" getValueFromEvent={handleLogotipo} noStyle>
                    <Upload.Dragger
                      name="logotipo"
                      accept="image/png, image/jpeg"
                      multiple={false}
                      customRequest={handleSubmitFile}
                      disabled={isDisabled}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: '#569A98' }} />
                      </p>
                      <p className="ant-upload-text">Clique ou arraste seu logotipo <br />para esta área</p> <br /> (3mb)
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="portfolio"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="portfolio" multiple={false} customRequest={handleSubmitFile} disabled={isDisabled}>
                    <Button style={{ width: '100%', textAlign: 'left', height: 42 }} disabled={isDisabled}>
                      <UploadOutlined /> Portfólio
                    </Button>
                  </Upload>
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </div>

      {user && auth &&
        <div className="px-3" style={{ backgroundColor: '#F4F4F4', paddingTop: 20 }}>
          <div className="container">
            <div className="row" >
              <div className="col-md-6">
                <h2 className="title-section">DADOS BANCÁRIOS</h2>
              </div>
              <div className="col-md-6">
                <Form
                  {...formItemLayout}
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item
                    className="custom-margin-form-item"
                    name="tipo_conta"
                  >
                    <Select placeholder="Tipo de conta" onChange={handleChangeTipoBanco} disabled={isDisabled} className="size_select">
                      <Option value="C">
                        Corrente
                    </Option>
                      <Option value="P">
                        Poupança
                    </Option>

                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="tipo_pessoa"
                  >
                    <Select placeholder="Tipo de pessoa" onChange={handleChangeCPFCNPJ} disabled={isDisabled} className="size_select">
                      <Option value="CPF">
                        CPF
                    </Option>
                      <Option value="CNPJ">
                        CNPJ
                    </Option>

                    </Select>
                  </Form.Item>

                  {(tipo_empresa && tipo_empresa !== 1) && (tipo_pessoa && tipo_pessoa === 'CNPJ') &&
                    <Form.Item
                      className="custom-margin-form-item"
                      name="cnpj_titular"
                    >
                      <MaskedInput mask="11.111.111/1111-11" placeholder="CNPJ" disabled={isDisabled} />
                    </Form.Item>
                  }

                  {tipo_empresa && tipo_empresa === 1 &&
                    <Form.Item
                      className="custom-margin-form-item"
                      name="cpf_titular"
                    >
                      <MaskedInput mask="111.111.111-11" placeholder="CPF" disabled={isDisabled} />
                    </Form.Item>
                  }

                  <Form.Item
                    className="custom-margin-form-item"
                    name="cd_banco"
                  >
                    <MaskedInput mask="111" placeholder="Código do Banco:" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="nome_banco"
                  >
                    <Input placeholder="Nome do Banco:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="operacao"
                  >
                    <MaskedInput mask="111" placeholder="Operação:" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="favorecido"
                  >
                    <Input placeholder="Favorecido:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="agencia"
                  >
                    <Input placeholder="Agência:" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="digito_agencia"
                  >
                    <Input placeholder="Dígito agência:" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    className="custom-margin-form-item"
                    name="conta"
                  >
                    <Input placeholder="Conta:" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    className="custom-form-item"
                    name="digito_conta"
                  >
                    <Input placeholder="Dígito conta:" disabled={isDisabled} />
                  </Form.Item>

                </Form>
              </div>
            </div>
          </div>
        </div>
      }

      {/* 4 SESSÃO */}
      {!id &&
        <div className="px-3" style={{ backgroundColor: (user && auth) ? '#FFF' : '#F4F4F4', paddingTop: 20 }}>
          <div className="container">
            <div className="row" >
              <div className="col-md-6">
                <h2 className="title-section">CONTATOS DA EMPRESA</h2>
              </div>
              <div className="col-md-6">
                <Form
                  {...formItemLayout}
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  {contatos && contatos.length > 0 && contatos.map((contato, index) => (
                    <div key={`${contato.id + ' - ' + index}`}>
                      <Form.Item
                        className="custom-margin-form-item"
                        name={`nomeFuncionario_${index}`}
                      >
                        <Input
                          placeholder="Nome contato:"
                          onChange={e => onChangeContato(e.target.value, index, 'nomeFuncionario')}
                          value={contato.nomeFuncionario}
                          disabled={isDisabled}
                          style={{ textTransform: 'uppercase' }}
                        // name={`nomeFuncionario_${index}`}
                        // id={`nomeFuncionario_${index}`}
                        />
                      </Form.Item>

                      <Form.Item
                        className="custom-margin-form-item"
                        name={`cargo_${index}`}
                      >
                        <Input placeholder="Cargo:"
                          onChange={e => onChangeContato(e.target.value, index, 'cargo')}
                          value={contato.cargo}
                          disabled={isDisabled}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </Form.Item>

                      <Form.Item
                        className="custom-margin-form-item"
                        name={`celular_contato_${index}`}
                      >
                        <MaskedInput mask="(11) 1111-1111" minLength={11}
                          placeholder="Celular:"
                          onChange={e => onChangeContato(e.target.value, index, 'celular')}
                          value={contato.celular}
                          disabled={isDisabled}
                        />
                      </Form.Item>

                      <Form.Item
                        className="custom-form-item"
                        name={`email_contato_${index}`}
                      >
                        <Input placeholder="E-mail:"
                          onChange={e => onChangeContato(e.target.value, index, 'email')}
                          value={contato.email}
                          disabled={isDisabled}
                          style={{ textTransform: 'lowercase' }}
                        />
                      </Form.Item>
                    </div>
                  ))
                  }

                  <Form.Item
                    className="custom-form-item"
                  >
                    <Button
                      type="dashed"
                      onClick={() => {
                        adicionarContato()
                      }}
                      style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <PlusOutlined /> Adicionar
                  </Button>
                  </Form.Item>

                </Form>
              </div>
            </div>
          </div>
        </div>
      }

      {/* 5 SESSÃO */}

      <div className="px-3" style={{ backgroundColor: id ? '#FFF' : (user && auth) ? '#F4F4F4' : '#FFF', paddingTop: 20 }}>
        <div className="container">
          <div className="row" >
            <div className="col-md-6">
              <h2 className="title-section">REDES SOCIAIS</h2>
            </div>
            <div className="col-md-6">
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  className="custom-margin-form-item"
                  name="facebook"
                >
                  <Input placeholder="Facebook:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="twitter"
                >
                  <Input placeholder="Twitter:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="linkedin"
                >
                  <Input placeholder="Linkedin:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-margin-form-item"
                  name="instagram"
                >
                  <Input placeholder="Instagram:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

                <Form.Item
                  className="custom-form-item"
                  name="youtube"
                >
                  <Input placeholder="Youtube:" disabled={isDisabled} style={{ textTransform: 'uppercase' }} />
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </div>

      {history && history.location && history.location.state === 'VIEW'
        ? null
        :

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div className="px-3" style={{ backgroundColor: '#FFF', paddingTop: 20 }}>
            <div className="container">
              <div className="row" >
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Form.Item
                    className="custom-form-item"
                  >

                    <Button
                      type="primary" htmlType="submit" id="btn_cadastrar_fornecedor" className="ant-input"
                      disabled={btnDisabled}
                    >
                      {id ? 'ATUALIZAR' : 'CADASTRAR'}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      }
    </>
  );
};
